import axios from 'axios';
import Layout from '../shared/Layout';
import { useContext, useEffect, useState } from 'react';
import DetailsContext from '../../providers/DetailsContext';
import language from '../../variables/language';
import { useLocation, Link, useNavigate  } from 'react-router-dom';
const Dashboard = () => {
  
  // const [params, setParams] = useState({});
  // const API_URL = process.env.REACT_APP_API_URL;
  // const moduleName = 'firmy';
  const label = 'dashboard';
 const { isLoggedIn } = useContext(DetailsContext);
 const location = useLocation();
 const navigate = useNavigate();
 useEffect( ()=>{
  if(isLoggedIn===false){
    navigate("login");
  }
 },[isLoggedIn])

  return (
    <Layout>
      <header  className='header-block'>
         <div className='header-txt txt-c' >{ language[language.lang][(location.pathname.replace("/",""))] }</div>
      </header>
      <main className='main-block' >
        <div className='main-content txt-c'>Dashboard</div>
      </main>
      
    </Layout>
  )
}

export default Dashboard