import  { useState ,useEffect} from 'react'
import Panels from '../../components/func panel/Panels'
import Search from '../../components/func panel/Search'

export default function FoldedHeader( {children,name,opened,done,setPaidId,paidId,idHundler}) {
   const [heigth, setHeight] = useState('fit-content');
   const [heigthMax, setHeightMax] = useState(0);
   const [myPaidId, setMyPaidId] = useState(paidId);
   const folder = {
      height:heigth,
      maxHeight:heigthMax,
      overflow:'hidden',
   }
   const heightHundler = (id) =>{
      if(heigthMax===0){
         setHeightMax('fit-content');
      }else{
         setHeightMax(0);
      }
      idHundler(id);
   }
   const paidFiltr = (index) =>{
       setPaidId(index);
     
   }
    useEffect(() => {
      setMyPaidId(paidId)
    }, [paidId]);
  return (
   <header  className='folded-header-block'>
      <div className='header-panel txt-c' >
            
         
            <div className='folded-header-btn' onClick={(e) =>paidFiltr('0')} style={{background:myPaidId == '0'? 'rgb(95 116 113 / 18%)' : 'transparent',borderBottom:myPaidId == '0'?  '0px solid rgba(94, 143, 199, 0.925)':'2px solid rgba(94, 199, 171, 0.925)' }}>
               <p>Public</p>
            </div>
            <div className='folded-header-btn' onClick={(e) =>paidFiltr('1')} style={{background:myPaidId == '1'? 'rgb(95 116 113 / 18%)' : 'transparent',borderBottom:myPaidId == '1'?  '0px solid rgba(94, 143, 199, 0.925)':'2px solid rgba(94, 199, 171, 0.925)'}}>
               <p>Personal</p>
            </div>
           
            {/* <div className='func-container'>
              <button className='func-button' onClick={paidFiltr}>
               {myPaidId === '0' ?
                (<svg xmlns="http://www.w3.org/2000/svg" className='search-icon' enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#e8eaed"><g><rect fill="none" height="24" width="24"/></g><g><path d="M12.89,11.1c-1.78-0.59-2.64-0.96-2.64-1.9c0-1.02,1.11-1.39,1.81-1.39c1.31,0,1.79,0.99,1.9,1.34l1.58-0.67 C15.39,8.03,14.72,6.56,13,6.24V5h-2v1.26C8.52,6.82,8.51,9.12,8.51,9.22c0,2.27,2.25,2.91,3.35,3.31 c1.58,0.56,2.28,1.07,2.28,2.03c0,1.13-1.05,1.61-1.98,1.61c-1.82,0-2.34-1.87-2.4-2.09L8.1,14.75c0.63,2.19,2.28,2.78,2.9,2.96V19 h2v-1.24c0.4-0.09,2.9-0.59,2.9-3.22C15.9,13.15,15.29,11.93,12.89,11.1z M3,21H1v-6h6v2l-2.48,0c1.61,2.41,4.36,4,7.48,4 c4.97,0,9-4.03,9-9h2c0,6.08-4.92,11-11,11c-3.72,0-7.01-1.85-9-4.67L3,21z M1,12C1,5.92,5.92,1,12,1c3.72,0,7.01,1.85,9,4.67L21,3 h2v6h-6V7l2.48,0C17.87,4.59,15.12,3,12,3c-4.97,0-9,4.03-9,9H1z"/></g></svg>) 
               :(<svg xmlns="http://www.w3.org/2000/svg" className='search-icon' height="24px" viewBox="0 0 24 24" width="24px" fill="#e8eaed"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M11.8 10.9c-2.27-.59-3-1.2-3-2.15 0-1.09 1.01-1.85 2.7-1.85 1.78 0 2.44.85 2.5 2.1h2.21c-.07-1.72-1.12-3.3-3.21-3.81V3h-3v2.16c-1.94.42-3.5 1.68-3.5 3.61 0 2.31 1.91 3.46 4.7 4.13 2.5.6 3 1.48 3 2.41 0 .69-.49 1.79-2.7 1.79-2.06 0-2.87-.92-2.98-2.1h-2.2c.12 2.19 1.76 3.42 3.68 3.83V21h3v-2.15c1.95-.37 3.5-1.5 3.5-3.55 0-2.84-2.43-3.81-4.7-4.4z"/></svg>) }
               
              </button>
            </div> */}
             <div className='func-container'>
              <button className='func-button' onClick={(e) => heightHundler('2')}>
              <svg xmlns="http://www.w3.org/2000/svg" className='search-icon'  enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#e8eaed"><rect fill="none" height="24" width="24"/><path d="M7,3H4v3H2V1h5V3z M22,6V1h-5v2h3v3H22z M7,21H4v-3H2v5h5V21z M20,18v3h-3v2h5v-5H20z M17,6H7v12h10V6z M19,18 c0,1.1-0.9,2-2,2H7c-1.1,0-2-0.9-2-2V6c0-1.1,0.9-2,2-2h10c1.1,0,2,0.9,2,2V18z M15,8H9v2h6V8z M15,11H9v2h6V11z M15,14H9v2h6V14z"/></svg>
                  
              </button>
            </div>
            <div className='func-container'>
              <button className='func-button' onClick={(e) => heightHundler('1')}>
                  <svg xmlns="http://www.w3.org/2000/svg" className='search-icon' viewBox="0 -960 960 960" ><path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"/></svg>
              </button>
            </div>
      </div>
      <div className='folder-container' style={folder}>
          {children}
      </div>
   
   </header>
  )
}
