import axios from 'axios';
import Layout from '../shared/Layout';
import { useContext, useEffect, useState } from 'react';
import language from '../../variables/language';
import { useLocation, Link, useParams  } from 'react-router-dom';
import Header from '../shared/Header';
import FlexList from '../../components/settings/FlexList';
import { Infopanel } from './Infopanel';
const Messages = () => {
  // const [params, setParams] = useState({});
  // const API_URL = process.env.REACT_APP_API_URL;
  // const moduleName = 'firmy';
  const label = 'messages';
  const { id } = useParams();
  const params = '?conversationprogress='+id;
  const url = '/api/messages';
  
 // const { setTheme } = useContext(DetailsContext);
 const location = useLocation();
 const [modalState ,setModalState] = useState('none');
 const [refresh, setRefresh] = useState(0);
 const [toUpdateId , setToUpdateId] = useState('');

 const [headers, setHeaders] = useState(
   {
    'isBot':{},
    // 'outerUser':{'name': 'name','function':'function'},
    'text':{},
   
    
    'answerConnector':{'name': 'point', 'name1':  {'name':'isRight'},'name2':  {'name':'estimationGroup'}},
    'typeId':{},
   
    'flagId':{},}
   );

 


   const stateHundler = (state,id) => {
     if(state === 'edit'){
       setModalState('edit');
       console.log("!!! stateHundler edit ->"+id);
       setToUpdateId(id);
     }else if(state === 'none'){
       setModalState('none');
     }else if(state === 'nextedit' || state === 'fromedit'){
      setModalState('none');
      setModalState('edit');
      setToUpdateId(id);
     }
   }
   const onRefreshHundler = ()=> {
     if(refresh==="1"){
       setRefresh('0');
     }else{
       setRefresh('1');
     }
     
   }


return (
 <Layout>
   <Header name={language[language.lang][label]}   opened='300px' >
    
   </Header>
   <main className='main-block' >
    
     <div className='main-content txt-c' >
     <Infopanel url={'/api/messages/byfunction/' + id}></Infopanel>
     <FlexList name=''
     table='table' 
     thead='table-header-bot-ans' htr='table-h-tr-bot-ans' 
     th='table-h-th-bot-ans ' tbody='table-body' 
     btr='table-b-tr-bot-mess' td='table-b-td-bot-ans' 
      url={url+params} done={refresh} stateHundler={stateHundler} onRefreshHundler={onRefreshHundler} headers={headers}/>
     </div>  
    
   </main>

 </Layout>
)
}

export default Messages