import axios from 'axios';
import Layout from '../shared/Layout';
import { useContext, useEffect, useState } from 'react';
import DetailsContext from '../../providers/DetailsContext';
import language from '../../variables/language';
import { useLocation, Link  } from 'react-router-dom';
import Header from '../shared/Header';
import Form from '../shared/Form';
import ActivityList from '../../components/settings/ActivityList';
import ModalUpdate from '../shared/ModalUpdate';
import ConversationUpdate from './ConversationUpdate';
import InnerHeader from './InnerHeader';
import ConvHeader from './ConvHeader';
const Conversations = () => {
  const [params, setParams] = useState('?isPublic=1');
  const [isPublic ,setIsPublic] = useState('1');
  // const API_URL = process.env.REACT_APP_API_URL;
  // const moduleName = 'firmy';
  const label = 'conversations';
  const url = '/api/conversations';
  const urlClean = 'https://games.english-from-zero.ovh/start?type=1&id=';
  const urlCard = '/conversations';
 // const { setTheme } = useContext(DetailsContext);
 const location = useLocation();
 const [modalState ,setModalState] = useState('none');
 const [refresh, setRefresh] = useState(0);
 const [toUpdateId , setToUpdateId] = useState('');
 const [key, setKey] = useState(0);

 const reloadComponent = () => {
   setKey(prevKey => prevKey + 1); // Zmień klucz, aby wymusić przeładowanie
 };

 const [headers, setHeaders] = useState(
   {'name':{},
    'description':{},
    'hashCode':{},
    'typeId':{},
    'level':{},
    'isPresent':{},
    'estimationFunction':{},
    'icons':{
      'icon-delete':{'name':'icon-delete','type' : 'icon','url':url,'value' : '','mode':'delete'},
      'icon-edit':{'name':'icon-edit','type' : 'icon','url':url,'value' : '','mode':'edit'},
      'icon-messages':{'name':'icon-messages','type' : 'icon','url':urlCard,'value' : '','mode':'card'},
      'icon-copy':{'name':'icon-copy','type' : 'icon','url':urlClean,'value' : '','mode':'copy'},
      'icon-copy1':{'name':'icon-copyurl','type' : 'icon','url':urlClean,'value' : '','mode':'copy-url','urlfield':'hashCode'},
    }
    }
   );
 const [inputs, setInputs] = useState(
   {
    'name':{'name':'name','type' : 'text','value' : ''},
    'description':{'name':'description','type' : 'text','value' : ''},
    'price':{'name':'price','type' : 'text','value' : ''},
   });
 const [selectors, setSelectors] = useState({
    'estimationFunction':{'name':'estimationFunction','type' : 'number','url':'/api/estimationfunctions','value' : ''},
    'typeId':{'name':'typeId','type' : 'number','url':'/api/conversationtypes','value' : ''},
    'level':{'name':'level','type' : 'number','url':'/api/levels','value' : ''},
    'isPresent':{'name':'isPresent','type' : 'number','url':'/api/booleans','value' : ''},
   });
 const [inputsToUpdate, setInputsToUpdate] = useState({
    'name':{'name':'name','type' : 'text','value' : ''},
    'description':{'name':'description','type' : 'text','value' : ''},
    'hashCode':{'name':'hashCode','type' : 'text','value' : '','disabled' : true},
    'price':{'name':'price','type' : 'text','value' : ''},
     
   });
 const [selectorsToUpdate, setSelectorsToUpdate] = useState({
    'estimationFunction':{'name':'estimationFunction','type' : 'number','url':'/api/estimationfunctions','value' : ''},
    'typeId':{'name':'typeId','type' : 'number','url':'/api/conversationtypes','value' : ''},
    'level':{'name':'level','type' : 'number','url':'/api/levels','value' : ''},
    'isPresent':{'name':'isPresent','type' : 'number','url':'/api/booleans','value' : ''},
   });
   const [mixedinputs, setMixednputs] = useState(
    {
     'typeId':{'name':'typeId','type' : 'number','tagtype' : 'select','url':'/api/conversationtypes','value' : '','disabled':false,'width':'29%'},
     'estimationFunction':{'name':'estimationFunction','type' : 'number','tagtype' : 'select','url':'/api/estimationfunctions','value' : '','disabled':false,'width':'19%'},
     'name':{'name':'name','type' : 'text', 'tagtype' : 'input','disabled':false,'width':'49%'},
     'level':{'name':'level', 'type' : 'number','tagtype' : 'select', 'value' : '','url':'/api/levels','disabled':false,'width':'9%'},
     'isPresent':{'name':'isPresent','type' : 'number', 'tagtype' : 'select','url':'/api/booleans','disabled':false,'width':'9%'},
     'price':{'name':'price','type' : 'number', 'tagtype' : 'input','disabled':false,'width':'9%'},
     'hashCode':{'name':'hashCode','type' : 'text', 'tagtype' : 'input','disabled':true,'width':'19%'},
     'description':{'name':'description','type' : 'text', 'tagtype' : 'textarea','disabled':false,'width':'49%'},
     
    });


   const stateHundler = (state,id) => {
     if(state === 'edit'){
       setModalState('edit');
       console.log("ID:"+id);
       setToUpdateId(id);
       reloadComponent();
     }else if(state === 'none'){
       setModalState('none');
     }
   }
   useEffect (()=>{
    onRefreshHundler();
   },[modalState]);
   const onRefreshHundler = ()=> {
     if(refresh==="1"){
       setRefresh('0');
     }else{
       setRefresh('1');
     }
     setModalState('none',toUpdateId);
    
   }
   useEffect(()=>{
    onRefreshHundler();
    setParams('?isPublic='+isPublic);
   },[isPublic])

return (
 <Layout>
   <ConvHeader paidId={isPublic} setPaidId={setIsPublic} name={language[language.lang][(location.pathname.replace("/",""))]}   opened='300px' >
     <Form inputs={inputs} selectors={selectors} url={url} onRefreshHundler={onRefreshHundler} name={language[language.lang].modals[label].createTitle}></Form>
   </ConvHeader>
   <main className='main-block' >
     <div className='main-content txt-c' >
     <ActivityList name='' url={url+ params} done={refresh} stateHundler={stateHundler} onRefreshHundler={onRefreshHundler} headers={headers}/>
     </div>  
     {/* <ModalUpdate modalState={modalState} inputs={inputsToUpdate}  selectors={selectorsToUpdate}  url={url} toUpdate={url+'/'+ toUpdateId }  onRefreshHundler={onRefreshHundler} stateHundler={stateHundler} name={language[language.lang].modals[label].updateDescription} title={language[language.lang].modals[label].updateTitle} /> */}
     <ConversationUpdate key={key} modalState={modalState} inputs={mixedinputs}  
    url={url} toUpdate={url+'/'+ toUpdateId }  
     onRefreshHundler={onRefreshHundler} stateHundler={stateHundler} 
     answerId={toUpdateId} 
     name={language[language.lang].modals[label].updateDescription} 
     title={language[language.lang].modals[label].updateTitle} />
   </main>

 </Layout>
)
}

export default Conversations