import axios from 'axios';
import Layout from '../shared/Layout';
import Form from '../../components/forms/Form';
import { useContext, useEffect, useState } from 'react';
import DetailsContext from '../../providers/DetailsContext';
import ActivityList from '../../components/settings/ActivityList';
import language from '../../variables/language';
import { useLocation, Link  } from 'react-router-dom';

import Header from '../shared/Header';
import ModalUpdate from '../shared/ModalUpdate';


const Matching = () => {

  const label = 'users';
  const location = useLocation();
  const [modalState ,setModalState] = useState('none');
  const hostName = 'https://games.english-from-zero.ovh';
  const urlForUser = hostName+'/matching-gaps?id=';
  const urlForIframe =hostName+ '/matching-gaps?id=';
  const [headers, setHeaders] = useState(
    { 'name':{},
    'icons':{
      'icon-delete':{'name':'icon-delete','type' : 'icon','url':'/api/grid','value' : '','mode':'delete'},
      'icon-edit':{'name':'icon-edit','type' : 'icon','url':'/api/grid','value' : '','mode':'edit'},
      'icon-card':{'name':'icon-card','type' : 'icon','url':'/matchexamples','value' : '','mode':'card'},
      'icon-copy':{'name':'icon-copyurl','type' : 'icon','url':urlForUser,'value' : '','mode':'copy-url','urlfield':'id'},
      'icon-copy1':{'name':'icon-copyframe','type' : 'icon','url':urlForIframe,'value' : '','mode':'copy-iframe','urlfield':'id'}
    }}
    );
  const [inputs, setInputs] = useState(
    {
     
     'name':{'name':'name','type' : 'text', 'tagtype' : 'input','disabled':false,'width':'99%'},
     'type':{'name':'type','type' : 'number', 'tagtype' : 'input','disabled':true,'width':'0%','value' : '4'},
 
});
  const [selectors, setSelectors] = useState({
  });
  const [inputsToUpdate, setInputsToUpdate] = useState(
    {'name':{'name':'name','type' : 'text','value' : ''},
});
  const [selectorsToUpdate, setSelectorsToUpdate] = useState({
  });
  const [icons, setIcons] = useState({
    'icon-delete':{'name':'icon-delete','type' : 'icon','url':'/api/grid','value' : ''},
    'icon-edit':{'name':'icon-edit','type' : 'icon','url':'/api/grid','value' : ''},
  });
  const [closerForm, setCloserForm] = useState(0);

  const formDoneHundler = () => {
    setCloserForm('0px');
  }
  const [toUpdateId , setToUpdateId] = useState('');
  const onRefreshHundler = ()=> {
    if(closerForm==="1px"){
      setCloserForm('0px');
    }else{
      setCloserForm('1px');
    }
    
  }
  const stateHundler = (state,id) => {
    if(state === 'edit'){
      setModalState('edit');
      setToUpdateId(id);
    }else if(state === 'none'){
      setModalState('none');
    }
  }

  return (
    <Layout>
      <Header name={'Matching words with gaps'}   opened='300px' >
        <Form inputs={inputs} selectors={selectors} url='/api/grid' onRefreshHundler={onRefreshHundler} name='Tutaj możesz dodać nowego grida'></Form>
      </Header>
      <main className='main-block' >
        <div className='main-content txt-c' >
        <ActivityList name='' url='/api/grid?type=4' done={closerForm} stateHundler={stateHundler} onRefreshHundler={onRefreshHundler} headers={headers}/>
        </div>  
        <ModalUpdate modalState={modalState} inputs={inputsToUpdate} selectors={selectorsToUpdate} url='/api/grid' toUpdate={'/api/grid/'+ toUpdateId } done={formDoneHundler} onRefreshHundler={onRefreshHundler} stateHundler={stateHundler} name={language[language.lang].modals[label].updateDescription} title={language[language.lang].modals[label].updateTitle} />
      </main>

    </Layout>
  )
}

export default Matching