import axios from 'axios';
import Layout from '../shared/Layout';
import Form from '../shared/Form';
import { useContext, useEffect, useState } from 'react';
import DetailsContext from '../../providers/DetailsContext';
import ActivityList from '../../components/settings/ActivityList';
import language from '../../variables/language';
import { useLocation, Link  } from 'react-router-dom';

import Header from '../shared/Header';
import ModalUpdate from '../shared/ModalUpdate';


const Groups = () => {
  const [modalState ,setModalState] = useState('none');
  const [headers, setHeaders] = useState(
    {'id':{}, 'name':{},'icons':{
      'icon-delete':{'name':'icon-delete','type' : 'icon','url':'/api/groups','value' : '','mode':'delete'},
      'icon-edit':{'name':'icon-edit','type' : 'icon','url':'/api/groups','value' : '','mode':'edit'},
    }}
    );
  const [inputs, setInputs] = useState(
    {
     'name':{'name':'name','type' : 'text','value' : ''}
});
  const [selectors, setSelectors] = useState({
  });
  const [inputsToUpdate, setInputsToUpdate] = useState(
    {'name':{'name':'name','type' : 'text','value' : ''},
});
  const [selectorsToUpdate, setSelectorsToUpdate] = useState({
  });
  const [icons, setIcons] = useState({
    'icon-delete':{'name':'icon-delete','type' : 'icon','url':'/api/groups','value' : ''},
    'icon-edit':{'name':'icon-edit','type' : 'icon','url':'/api/groups','value' : ''},
  });
  const [closerForm, setCloserForm] = useState(0);
  // const API_URL = process.env.REACT_APP_API_URL;
  // const moduleName = 'firmy';
  const label = 'users';
  const location = useLocation();
  const formDoneHundler = () => {
    setCloserForm('0px');
  }
  const [toUpdateId , setToUpdateId] = useState('');
  const onRefreshHundler = ()=> {
    if(closerForm==="1px"){
      setCloserForm('0px');
    }else{
      setCloserForm('1px');
    }
    
  }
  const stateHundler = (state,id) => {
    if(state === 'edit'){
      setModalState('edit');
      setToUpdateId(id);
    }else if(state === 'none'){
      setModalState('none');
    }
  }
 // const { setTheme } = useContext(DetailsContext);


  return (
    <Layout>
      <Header name={language[language.lang][(location.pathname.replace("/",""))]}   opened='300px' >
        <Form inputs={inputs} selectors={selectors} url='/api/groups' onRefreshHundler={onRefreshHundler} name='Tutaj możesz dodać nowego uzytkownika'></Form>
      </Header>
      <main className='main-block' >
        <div className='main-content txt-c' >
        <ActivityList name='' url='/api/groups' done={closerForm} stateHundler={stateHundler} onRefreshHundler={onRefreshHundler} headers={headers}/>
        </div>  
        <ModalUpdate modalState={modalState} inputs={inputsToUpdate} selectors={selectorsToUpdate} url='/api/groups' toUpdate={'/api/groups/'+ toUpdateId } done={formDoneHundler} onRefreshHundler={onRefreshHundler} stateHundler={stateHundler} name={language[language.lang].modals[label].updateDescription} title={language[language.lang].modals[label].updateTitle} />
      </main>

    </Layout>
  )
}

export default Groups