import { useEffect, useState } from 'react';
import '../../css/modals.css';
import axios from 'axios';
import {  useNavigate } from 'react-router-dom';
import ScheduleMenu from './ScheduleMenu';
import FormInterface from '../conversations/FormInterface';
import FlexList from '../../components/settings/FlexList';
import InnerHeader from '../conversations/InnerHeader';
import Form from '../../components/forms/Form';
import IconDelete from '../../components/icons/IconDelete';
export default function ModalLesson(props) {
   const navigate = useNavigate();
   const [visibility, setVisibility] = useState('none');
   const [inputs, setInputs] = useState({...props.inputs });
   const [data,setData] =  useState([]);
   const [dataLesson,setDataLesson] =  useState([]);
   const mainUrl = "/api/lessons/"+ props.lessonid;
   const url = "/api/events";
   const [satatus, setSatatus] = useState('wait');
   const byLessonParams = "?lesson="+props.lessonid;
   const modalHideHundler = () => {
      setVisibility('none');
      props.stateHundler('none');
   }

   const [inputsToUpdate, setInputsToUpdate] = useState(
      {
        'start':{'name':'start', 'type' : 'time','tagtype' : 'input', 'value' : '','disabled':false,'width':'29%'},
        'end':{'name':'end', 'type' : 'time','tagtype' : 'input', 'value' : '','disabled':false,'width':'29%'},
        'date':{'name':'date', 'type' : 'date','tagtype' : 'input', 'value' : '','disabled':false,'width':'39%'},

        'price':{'name':'price', 'type' : 'float','step':'0.01','tagtype' : 'input', 'value' : '','disabled':false,'width':'29%'},
        'duration':{'name':'duration', 'type' : 'float','step':'0.01','tagtype' : 'input', 'value' : '','disabled':false,'width':'29%'},
        'groupe':{'name':'groupe','type' : 'number','tagtype' : 'select','url':'/api/groups','value' : '','disabled':false,'width':'39%'},

        'teacher':{'name':'teacher','type' : 'number','tagtype' : 'select','url':'/api/users','value' : '','disabled':false,'width':'29%'},
        'isCycled':{'name':'isCycled','type' : 'number','tagtype' : 'select','url':'/api/booleans','value' : '','disabled':false,'width':'29%'},
        'hasBroadcast':{'name':'hasBroadcast','type' : 'number','tagtype' : 'select','url':'/api/booleans','value' : '','disabled':false,'width':'39%'},

        'course':{'name':'course', 'type' : 'text','tagtype' : 'textarea', 'value' : '','disabled':false,'width':'99%'},
        'comment':{'name':'comment','type' : 'number','tagtype' : 'textarea','value' : '','disabled':false,'width':'99%'},
        'resources':{'name':'resources','type' : 'number','tagtype' : 'textarea','value' : '','disabled':false,'width':'99%'},
        'homework':{'name':'homework', 'type' : 'text','tagtype' : 'textarea', 'value' : '','disabled':false,'width':'99%'},
 
      });
   const [inputsEvents, setInputsEvents] = useState(
      {
        'student':{'name':'student','type' : 'number','tagtype' : 'select','url':'/api/students','value' : '','disabled':false,'width':'99%'},
        'lesson':{'name':'lesson','type' : 'number','tagtype' : 'select','url':'/api/lessons','value' : props.lessonid,'disabled':true,'width':'0%'},

      });
 
      const [headersEvents, setHeadersEvents] = useState(
         {
          'student':{'name':'name','name1':'surname'},
          'toPay':{'name':'boolean'},
          'attendence':{'name':'boolean'},
          'icons':{
            'icon-card':{'name':'icon-card','type' : 'icon','url':'/students','value' : '','mode':'cardevent'},
            'icon-delete':{'name':'icon-delete','type' : 'icon','url':url,'value' : '','mode':'delete'},}
          }
         );
   useEffect(()=>{
      if(props.modalState === 'menu'){
        
         setVisibility('flex');
      }else{
         setVisibility('none');
      }
      
   },[props.modalState]);
  

   useEffect(()=>{
      setInputsEvents({
         'student':{'name':'student','type' : 'number','tagtype' : 'select','url':'/api/students','value' : '','disabled':false,'width':'99%'},
         'lesson':{'name':'lesson','type' : 'number','tagtype' : 'select','url':'/api/lessons','value' : props.lessonid,'disabled':true,'width':'0%'},
       
         //  'probability':{'name':'probability','type' : 'number','value' : ''},
       });
      if(props.modalState === "menu" ){

         if(!props.toUpdate.includes('dateFrom')){
            console.log('get schedule');
            axios.get(props.toUpdate, { withCredentials: true })
            .then(response => {
              setData([]);
              console.log('stages response piplen')
              setData(response.data);
              setSatatus('none');
            })
            .catch(err => {
            //   if(err.response.status === 401 ){
            //    navigate('/login');
            //   }
              setSatatus('error');
              console.log(err);
            });
         }
      
        
         axios.get(mainUrl, { withCredentials: true })
         .then(response => {
            setDataLesson(response.data);
            // console.log("modal update data +>" +JSON.stringify(data, null, 2));
            
         })
         .catch(err => {
            if(err.response.status === 401 ){
               console.log(err);
               navigate('/login');
             }else if(err.response.status === 400 ){
               modalHideHundler();
             } else{
               console.log(err);
             }
         });
        }
        
         
      
     },[props.modalState,props.onRefreshHundler])
     const handleButtonClick = (event) => {
      event.stopPropagation(); 
    };
    const deleteProcess =()=>{
      if(window.confirm("Do you want delete this element?")){
            axios.delete(mainUrl, {
               withCredentials: true,
               headers: {
                     'Content-Type': 'application/json',
               },
            }
            ).then(response => {
               props.onRefreshHundler();
            })
            .catch(err => {
               window.alert("Елемент не видалено скоріш за все він пов'язаний з іншими елементами, тож спочатку переконайтеся чи жоден елемент більше не повязаний з цим !");
               console.log('Błąd podczas wysyłania żądania:', err.message);
            });
         }
     }

  return (
    <div className='modal-menu'  onClick={modalHideHundler} style={{display:visibility}} >

   <div onClick={handleButtonClick} className='bg-color-modal'  style={{display:'flex',flexDirection:'row',marginBlock:'auto',justifyContent:'center',
   height:'fit-content',width:'70%', padding:10,marginInline:'auto',borderRadius:20,borderTop:'30px solid rgb(82 143 236 / 20%)',borderBottom: '20px solid rgb(82 236 193 / 13%)'
      }}>
         <div style={{marginBlock:'auto',height:'fit-content',position:'relative'}}>
            <h4 className='txt-c' style={{marginLeft:6,marginBlock:0}}>Lesson Card</h4>
         
            <FormInterface inputs={inputsToUpdate} 
                     url={mainUrl} 
                     data={dataLesson} 
                     onRefreshHundler={props.onRefreshHundler}  
                     name={'Lesson Card'} title={'Lesson Card'} />
            <div style={{position:'absolute', bottom:10,left:5}}>
               <div className='func-button' style={{marginTop:5,marginLeft:5}}  onClick={deleteProcess}>
               <IconDelete fillColor={'icon-red'}  style={{width:20,height:20}}/>
               </div>
            </div>
         </div>
         <div style={{padding:10,minWidth:'40%'}}>
            <InnerHeader name='Add participants'    opened='300px' >
               <Form inputs={inputsEvents}  url={url} refresh={props.refresh}
               onRefreshHundler={props.onRefreshHundler} 
               name=''></Form>
            </InnerHeader>
            { 
               (byLessonParams)?
               (<FlexList name='' table='table' 
                  thead='table-header-bot-con' htr='table-h-tr-bot-con' 
                  th='table-h-th-bot-con ' tbody='table-body' 
                  btr='table-b-tr' td='table-b-td' 
                   url={url+byLessonParams} done={props.refresh} stateHundler={props.stateHundler} 
                   onRefreshHundler={props.onRefreshHundler} headers={headersEvents}/>)
               :
               (<div>Loading...</div>)
            }
             
         </div>
        
         
         
       
          
   </div>
      

       
      


      {/* <ClientWindow inputs={inputs} selectors={props.selectors} url={props.toUpdate}   onRefreshHundler={props.onRefreshHundler}  name={props.name} title={props.title} /> */}
    </div>
  )
}
