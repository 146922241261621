import axios from 'axios';
import Layout from '../shared/Layout';
import { useContext, useEffect, useState } from 'react';
import DetailsContext from '../../providers/DetailsContext';
import language from '../../variables/language';
import { useLocation, Link ,useParams,useSearchParams } from 'react-router-dom';
import Header from '../shared/Header';
import FormClient from './FormStudent';
import {  useNavigate } from 'react-router-dom';
import Events from './Events';
import FormStudent from './FormStudent';
const StudentCard = () => {
  const location = useLocation();
  const label = 'students';
  const { id } = useParams();
    const url = '/api/students/'+id;
    const [searchParams] = useSearchParams();
    const [modalState ,setModalState] = useState('none');
    const [refresh, setRefresh] = useState(0);
    const [toUpdateId , setToUpdateId] = useState('');
    const [mode, setMode] = useState('edit');
    const [statusform, setStatusForm] = useState('none');
    const [data, setData] = useState([]);
    const navigate = useNavigate();
  
    const [inputs, setInputs] = useState(
      {
       'name':{'name':'name','type' : 'text','value' : ''},
       'surname':{'name':'surname','type' : 'text','value' : ''},
       'gMeetId':{'name':'gMeetId','type' : 'text','value' : ''},
       'gdocument':{'name':'gdocument','type' : 'text','value' : ''},
       'gDictionaryUa':{'name':'gDictionaryUa','type' : 'text','value' : ''},
       'gDictionaryEng':{'name':'gDictionaryEng','type' : 'text','value' : ''},
      
       'email':{'name':'email','type' : 'text','value' : ''},
       'telegramUsername':{'name':'telegramUsername','type' : 'text','value' : ''},
       'facebook':{'name':'facebook','type' : 'text','value' : ''},
       'schedule':{'name':'schedule','type' : 'text','value' : ''},
       'ocupation':{'name':'ocupation','type' : 'text','value' : ''},
       'location':{'name':'location','type' : 'text','value' : ''},
      
       
      });
    const [selectors, setSelectors] = useState({
       'responsibleId':{'name':'responsibleId','type' : 'number','url':'/api/users','value' : ''},
       'project':{'name':'project','type' : 'number','url':'/api/projects','value' : ''},
       'level':{'name':'level','type' : 'number','url':'/api/levels','value' : ''},
       'groupe':{'name':'groupe','type' : 'number','url':'/api/groups','value' : ''},
       'timezone':{'name':'timezone','type' : 'number','url':'/api/timezones','value' : ''},
       'hasBroadcast':{'name':'hasBroadcast','type' : 'number','url':'/api/booleans','value' : ''},
      });


 

      const stateHundler = (state,id) => {
        if(state === 'edit'){
          setModalState('edit');
          // console.log("ID:"+id);
          setToUpdateId(id);
        }else if(state === 'none'){
          setModalState('none');
        }
      }
      const onRefreshHundler = ()=> {
        if(refresh==="1"){
          setRefresh('0');
        }else{
          setRefresh('1');
        }
        
      }

      
   useEffect(()=>{
    axios.get(url, { withCredentials: true })
    .then(response => {
       setData(response.data);
       // console.log("modal update data +>" +JSON.stringify(data, null, 2));
       setStatusForm('none');
    })
    .catch(err => {
       setStatusForm('error');
       if(err.response.status === 401 ){
          // console.log(err);
          navigate('/login');
        }else{
          // console.log(err);
        }
    });
 },[url,refresh])


 useEffect(()=>{
    const updatedInputs = { ...inputs }
    Object.keys(inputs).forEach(fieldName => {
       updatedInputs[fieldName].value = data[fieldName]+'';
    //   // console.log(inputs[fieldName].name+ '<- is )(inmodal window)()()->' + updatedInputs[fieldName].value);
     });
     const updatedSelectors = { ...selectors }
   //  // console.log('selectora obj :'+ JSON.stringify(selectors, null, 2));
     Object.keys(selectors).forEach(fieldName => {
       if (updatedSelectors.hasOwnProperty(fieldName)) {
          // Jeśli pole już istnieje, aktualizujemy jego wartość
          // console.log("selectors> name: "+fieldName+' | data: '+JSON.stringify(data[fieldName], null, 2));
           var id = data[fieldName] && typeof data[fieldName] === 'object' && data[fieldName]['id'] ? data[fieldName]['id'] :data[fieldName];
          updatedSelectors[fieldName].value = id;
      } else {
          var id = data[fieldName] && typeof data[fieldName] === 'object' && data[fieldName]['id'] ? data[fieldName]['id'] : data[fieldName];
          // Jeśli pole nie istnieje, dodajemy nowe pole do obiektu inputs
          updatedSelectors[fieldName] = {
              'value': id
          };
       }
     //  // console.log('selectors all ---------------- obj :'+ JSON.stringify(selectors, null, 2));
     //  // console.log(inputs[fieldName].name+ '<- is ->' + formData[fieldName]);
     });
     setSelectors(updatedSelectors);
     setInputs(updatedInputs);
     // console.log('selectors afte  ><><><><><><><><><><><:'+ JSON.stringify(selectors, null, 2));
     
 },[data]);

  return (
    <Layout>
    {/* <Header name={language[language.lang]['client']}   opened='300px' >
     <div></div>
    </Header> */}
    <header style={{height:15}} ></header>
    <main className='main-block' >
      <div className='main-content txt-c' >
        <FormStudent clasWrapper='wide-input-wraper' id={id} clasContainer='wide-container'  clasInputs='wide-input' clasSelectors='wide-selection' inputs={inputs} selectors={selectors} status={statusform} url={url} mode={mode} data={data} name='ssd' onRefreshHundler={onRefreshHundler} ></FormStudent>
      </div>
      <Events></Events>  

    </main>

  </Layout>
  )
}

export default StudentCard;