import React, { useEffect, useRef, useState } from 'react'
import LessonsColumn from './LessonsColumn'
import axios from 'axios';
import '../../css/pipeline.css'
import {  useNavigate } from 'react-router-dom';
export default function ScheduleContainer(props) {

   const navigate = useNavigate();
   const [data,setData] =  useState([]);
   const [satatus, setSatatus] = useState('none');
   const [refresh,setRefresh] = useState('0');


   useEffect(() => {
    if(satatus === 'none'){

    }else if(satatus === 'wait'){
     
    }else if(satatus === 'done'){
   
    }else if(satatus === 'error'){
      
    }else if(satatus === 'forbidden'){
    
    }else if(satatus === 'timeout'){
      
    }
  }, [satatus]);

    const onRefreshHundler = ()=> {
      console.log("Container - url :"+props.url)
      if(refresh==="1"){
        setRefresh('0');
      }else{
        setRefresh('1');
      }
      
    }

   
   useEffect(()=>{
    if(satatus !== 'wait'){
      setSatatus('wait');
      console.log('2 - get Schedule'+props.url);
      axios.get(props.url, { withCredentials: true })
      .then(response => {
        setData([]);
        console.log('container request responsed by url:'+props.url)
        setData(response.data);
       // onRefreshHundler();
        setSatatus('none');
      })
      .catch(err => {
      //   if(err.response.status === 401 ){
      //    navigate('/login');
      //   }
        console.log(err);
      });
    }
   
   },[props.refresh])



   const scrollRef = useRef(null);
   const [isDragging, setIsDragging] = useState(false);
   const [startX, setStartX] = useState(0);
   const [scrollLeft, setScrollLeft] = useState(0);
 
   const handleMouseDown = (e) => {
     setIsDragging(true);
     setStartX(e.pageX - scrollRef.current.offsetLeft);
     setScrollLeft(scrollRef.current.scrollLeft);
   };
 
   const handleMouseMove = (e) => {
     if (!isDragging) return;
     e.preventDefault(); // zapobiega zaznaczaniu tekstu
     const x = e.pageX - scrollRef.current.offsetLeft;
     const walk = (x - startX) * 2; // szybkość przesuwania
     scrollRef.current.scrollLeft = scrollLeft - walk;
   };
 
   const handleMouseUp = () => {
     setIsDragging(false);
   };
 
   const handleMouseLeave = () => {
     setIsDragging(false);
   };



  return (
    <div className={(data && data.length > 7)?'pipe-container horizontal-scroll':'pipe-container'}   ref={scrollRef}
    onMouseDown={handleMouseDown} style={ (data && data.length > 7)? { justifyContent:'start' }:{justifyContent:'space-between'} }
    onMouseMove={handleMouseMove}
    onMouseUp={handleMouseUp}
    onMouseLeave={handleMouseLeave}>
      {Array.isArray(data) ? (
               data.map(item => (

                  <LessonsColumn  stateHundler={props.stateHundler} data={data} style={(data && data.length > 7)?{ maxWidth:220}:{maxWidth:280}}
                   key={item.id} id={item.id} day={item} title={item.dayOfWeek} color={item.color}
                   onRefresh={onRefreshHundler}   onRootRefresh={props.onRefreshHundler} refresh={refresh} url='/api/lessons'></LessonsColumn>

               ))
            ) : (
               <div key='item-empty'>Empty list</div>
         )}
    </div>
  )
}
