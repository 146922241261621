import { useEffect, useState } from 'react';
import '../../css/modals.css';
import ModalWindow from '../shared/ModalWindow';
import axios from 'axios';
import {  useNavigate } from 'react-router-dom';
import WindowInterface from './FormInterface';
import FormInterface from './FormInterface';
import ActivityList from '../../components/settings/ActivityList';
import FlexList from '../../components/settings/FlexList';
import Header from '../shared/Header';
import Form from '../../components/forms/Form';
import InnerHeader from './InnerHeader';
export default function ModalInterface(props) {
   const navigate = useNavigate();
   const [visibility, setVisibility] = useState('none');
   const labelFrom = 'fromId';
   const labelNext = 'nextId';
   const [modalState ,setModalState] = useState('none');
   const [onSelectorUpdate ,setOnSelectorUpdate] = useState(props.onSelectorUpdate);
   const [refresh, setRefresh] = useState(0);
   const [toUpdateId , setToUpdateId] = useState(props.answerId);
   const [inputs, setInputs] = useState({...props.inputs });
   const [data, setData] = useState([]);
   const url = "/api/answerconnectors";
   const urlAnswer = "/api/answers";
   const paramsNext = "?nextId="+props.answerId;
   const paramsFrom = "?fromId="+props.answerId;
   const modalHideHundler = () => {
      setVisibility('none');
      props.stateHundler('none');
   }
   const stopProp = (event) => {
      event.stopPropagation();
   }



   const [headersByNext, setHeadersByNext] = useState(
      {'text':{},
       'isRight':{},
       'url':{},
       'point':{},
       'estimationGroup':{},
       'fromId':{'name':'text'},
       'icons':{
         'icon-delete':{'name':'icon-delete','type' : 'icon','url':url,'value' : '','mode':'delete'},
         'icon-edit':{'name':'icon-forward','type' : 'icon','url':'/answers','value' : '','mode':'fromedit'},}
       }
      );
   const [headersByFrom, setHeadersByFrom] = useState(
         {'text':{},
          'isRight':{},
          'url':{},
          'point':{},
          'estimationGroup':{},
          'nextId':{'name':'text'},
          'icons':{
            'icon-delete':{'name':'icon-delete','type' : 'icon','url':url,'value' : '','mode':'delete'},
            'icon-edit':{'name':'icon-forward','type' : 'icon','url':'/answers','value' : '','mode':'nextedit'},}
          }
         );
   const [mixedinputs, setMixednputs] = useState(
            {
             'fromId':{'name':'fromId','type' : 'number', 'tagtype' : 'select','url':'/api/answers'+props.convParams, 'value' : toUpdateId, 'disabled':true,'width':'39%'},
             'nextId':{'name':'nextId','type' : 'number','tagtype' : 'select','url':'/api/answers'+props.convParams,'value' : '','disabled':false,'width':'39%'},
             'estimationGroup':{'name':'estimationGroup','type' : 'number','tagtype' : 'select','url':'/api/estimationgroup'+props.convParams,'value' : '','disabled':false,'width':'19%'},
             'isRight':{'name':'isRight', 'type' : 'number','tagtype' : 'select', 'value' : '','url':'/api/booleans','disabled':false,'width':'9%'},
             'point':{'name':'point','type' : 'number', 'tagtype' : 'input','disabled':false,'width':'9%'},
             'url':{'name':'url','type' : 'number', 'tagtype' : 'input','disabled':false,'width':'14%'},
             'clasification':{'name':'clasification','type' : 'number', 'tagtype' : 'select','url':'/api/clasifications', 'value' : '0', 'disabled':false,'width':'14%'},
             'text':{'name':'text','type' : 'text', 'tagtype' : 'input','disabled':false,'width':'49%'},
            });
  
   useEffect(()=>{
      if(props.modalState === 'edit' && props.answerId){
         axios.get(props.toUpdate, { withCredentials: true })
            .then(response => {
               setData(response.data);
             //  console.log("modal update data +>" +JSON.stringify(data, null, 2));
               
            })
            .catch(err => {
               if(err.response.status === 401 ){
                  console.log(err);
                  navigate('/login');
                }else{
                  console.log(err);
                }
            });
         setVisibility('block');
      }
      
    },[props.modalState,props.inputs,props.onRefreshHundler]);

   useEffect(()=>{
      setInputs({...props.inputs });
      setToUpdateId(props.answerId);
      console.log("*******************************refreshes --------------------"+props.answerId);
      setMixednputs({
         'fromId':{'name':'fromId','type' : 'number', 'tagtype' : 'select','url':'/api/answers'+props.convParams, 'value' : props.answerId, 'disabled':true,'width':'39%'},
         'nextId':{'name':'nextId','type' : 'number','tagtype' : 'select','url':'/api/answers'+props.convParams,'value' : '','disabled':false,'width':'39%'},
         'estimationGroup':{'name':'estimationGroup','type' : 'number','tagtype' : 'select','url':'/api/estimationgroup'+props.convParams,'value' : '','disabled':false,'width':'19%'},
         'isRight':{'name':'isRight', 'type' : 'number','tagtype' : 'select', 'value' : '','url':'/api/booleans','disabled':false,'width':'9%'},
         'point':{'name':'point','type' : 'number', 'tagtype' : 'input','disabled':false,'width':'9%'},
         'url':{'name':'url','type' : 'number', 'tagtype' : 'input','disabled':false,'width':'14%'},
         'clasification':{'name':'clasification','type' : 'number', 'tagtype' : 'select','url':'/api/clasifications', 'value' : '', 'disabled':false,'width':'14%'},
         'text':{'name':'text','type' : 'text', 'tagtype' : 'input','disabled':false,'width':'49%'},
        });
        onRefreshHundler();
   },[props.inputs,props.answerId])

   const onRefreshHundler = ()=> {
      if(refresh==="1"){
        setRefresh('0');
      }else{
        setRefresh('1');
      }
      setToUpdateId(props.answerId);
      console.log("on refresh hundler ++");
    }


    const stateHundler = (state,id) => {
      if(state === 'edit'){
        setModalState('edit');
        console.log("ID:"+id);
        setToUpdateId(id);
      }else if(state === 'none'){
        setModalState('none');
      }else if(state === 'nextedit' || state === 'fromedit'){
         props.stateHundler(state,id);
      }
    }
  return (
    <div className='modal-update' onClick={modalHideHundler} style={{display:visibility}} >
        <div className='modal-window' onClick={stopProp}>
            <div style={{display:'flex',flexDirection:'row'}}>
               
               <h3 className='modal-w-title txt-c' style={{marginTop:20,marginBlock:10}}>Incoming buttons<svg style={{rotate:'180deg'}} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#e8eaed"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M14 8.83L17.17 12 14 15.17V14H6v-4h8V8.83M12 4v4H4v8h8v4l8-8-8-8z"/></svg></h3>
            </div>
            
            {
               props.answerId  &&  paramsNext && paramsNext.includes(props.answerId) ?(  <FlexList name='' table='table' 
                  thead='table-header-bot-con' htr='table-h-tr-bot-con' 
                  th='table-h-th-bot-con ' tbody='table-body' 
                  btr='table-b-tr-bot-con' td='table-b-td-bot-con'  url={url+paramsNext} done={refresh} stateHundler={stateHundler} onRefreshHundler={onRefreshHundler} headers={headersByNext}/>)
               :
               ('')
            }
          
            {/* <h3 className='modal-w-title txt-c'style={{marginTop:20,marginBlock:10}} >{props.title}</h3> */}
            <FormInterface inputs={props.inputs} url={urlAnswer+"/"+props.answerId} data={data} onRefreshHundler={props.onRefreshHundler}  name={props.name} title={props.title} />
          
            <div style={{display:'flex',flexDirection:'row'}}>
               <h3 className='modal-w-title txt-c' style={{marginTop:20,marginBlock:10,paddingTop:10,borderTop:'1px solid #8080802e',width:'100%',textAlign:'center'}} >Outcoming buttons 
                  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#e8eaed"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M14 8.83L17.17 12 14 15.17V14H6v-4h8V8.83M12 4v4H4v8h8v4l8-8-8-8z"/></svg></h3>
            </div>
            <InnerHeader name='Add new button'    opened='300px' >
               <Form inputs={mixedinputs}  url={url} refresh={toUpdateId}
               onRefreshHundler={onRefreshHundler} 
               name=''></Form>
            </InnerHeader>

            {
              props.answerId  && paramsFrom && paramsFrom.includes(props.answerId)?( <FlexList name='' table='table' 
                  thead='table-header-bot-con' htr='table-h-tr-bot-con' 
                  th='table-h-th-bot-con ' tbody='table-body' 
                  btr='table-b-tr-bot-con' td='table-b-td-bot-con' 
                   url={url+paramsFrom} done={refresh} stateHundler={stateHundler} onRefreshHundler={onRefreshHundler} headers={headersByFrom}/>)
               :
               ('')
            }
          


        </div>
    </div>
  )
}
