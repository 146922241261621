import axios from 'axios';
import Layout from '../shared/Layout';
import { useContext, useEffect, useRef, useState } from 'react';
import DetailsContext from '../../providers/DetailsContext';
import language from '../../variables/language';
import { useLocation, Link  } from 'react-router-dom';
import Header from '../shared/Header';
import Form from '../../components/forms/Form';
import ModalSchedule from './ModalSchedule';
import ScheduleContainer from './ScheduleContainer';
import FunctionalHeader from '../../components/func panel/FunctionalHeader';
import Function from '../../components/func panel/Function';
import IconAdd from '../../components/icons/IconAdd';
import IconSearch from '../../components/icons/IconSearch';
import IconFilter from '../../components/icons/IconFilter';
import SearchForm from '../../components/forms/SearchForm';
import IconArrow from '../../components/icons/IconArrow';
import IconCycled from '../../components/icons/IconCycled';
import { Infopanel } from '../../components/infopanel/Infopanel';
const Schedule = () => {
  const label = 'lessons';
  const location = useLocation();
  const url = '/api/lessons';

  const [weekBias ,setWeekBias] = useState();
  const [urlSchedule ,setUrlSchedule] = useState('/api/schedule');
  const [urlInfopanel ,setUrlInfopanel] = useState('/api/schedule/infopanel');
  const [modalState ,setModalState] = useState('none');
  
  const [refresh, setRefresh] = useState(0);
  const [refreshContainer, setRefreshContainer] = useState(0);
  const [refreshModal, setRefreshModal] = useState(0);
  const [toUpdateId , setToUpdateId] = useState('');
  const [headerContainer , setHeaderContainer] = useState(0);
  const [headerHeight , setHeaderHeight] = useState(0);
  const [cycled , setCycled] = useState(0);
  const [searchParameters , setSearchParameters] = useState('');
  const elementRef = useRef(null);
  const elementRef2 = useRef(null);
  const [headers, setHeaders] = useState(
    {'start':{}, 'end':{}, 'course':{},'duration':{},'homework':{},'price':{},'icons':{
      'icon-delete':{'name':'icon-delete','type' : 'icon','url':url,'value' : '','mode':'delete'},
      'icon-card':{'name':'icon-card','type' : 'icon','url':url,'value' : '','mode':'card'},
      'icon-edit':{'name':'icon-edit','type' : 'icon','url':url,'value' : '','mode':'edit'},
    }}
    );
  const [inputs, setInputs] = useState(
    {
     'start':{'name':'start', 'type' : 'time','tagtype' : 'input', 'value' : '','disabled':false,'width':'10%'},
     'end':{'name':'end', 'type' : 'time','tagtype' : 'input', 'value' : '','disabled':false,'width':'10%'},
     'date':{'name':'date', 'type' : 'date','tagtype' : 'input', 'value' : '','disabled':false,'width':'20%'},
     'price':{'name':'price', 'type' : 'float','step':'0.01','tagtype' : 'input', 'value' : '','disabled':false,'width':'10%'},
     'duration':{'name':'duration', 'type' : 'float','step':'0.01','tagtype' : 'input', 'value' : '','disabled':false,'width':'20%'},
     'groupe':{'name':'groupe','type' : 'number','tagtype' : 'select','url':'/api/groups','value' : '','disabled':false,'width':'20%'},
    });
    const [inputsSearch, setInputsSearch] = useState(
      {
       'dateFrom':{'name':'dateFrom', 'type' : 'date','tagtype' : 'input', 'value' : '','disabled':false,'width':'49%'},
       'dateTo':{'name':'dateTo', 'type' : 'date','tagtype' : 'input', 'value' : '','disabled':false,'width':'49%'},
      });
  const [selectors, setSelectors] = useState({
    });
  const [inputsToUpdate, setInputsToUpdate] = useState(
    {
      'start':{'name':'start', 'type' : 'time','tagtype' : 'input', 'value' : '','disabled':false,'width':'10%'},
      'end':{'name':'end', 'type' : 'time','tagtype' : 'input', 'value' : '','disabled':false,'width':'10%'},
      'date':{'name':'date', 'type' : 'date','tagtype' : 'input', 'value' : '','disabled':false,'width':'20%'},
      'price':{'name':'price', 'type' : 'float','step':'0.01','tagtype' : 'input', 'value' : '','disabled':false,'width':'10%'},
      'duration':{'name':'duration', 'type' : 'float','step':'0.01','tagtype' : 'input', 'value' : '','disabled':false,'width':'20%'},
      'groupe':{'name':'groupe','type' : 'number','tagtype' : 'select','url':'/api/groups','value' : '','disabled':false,'width':'20%'},
      //  'probability':{'name':'probability','type' : 'number','value' : ''},
    });
 

    const stateHundler = (state,id) => {
      if(state === 'edit'){
        setModalState('edit');
        setToUpdateId(id);
      }else if(state === 'none'){
        setModalState('none');
      }else if(state === 'menu'){
        setModalState('menu');
        setToUpdateId(id);
      }
    }
    const onRefreshHundler = ()=> {
      
      if(refresh==="1"){
        setRefresh('0');
      }else{
        setRefresh('1');
      }
      console.log('0 - refreched')
      
    }

    const onRefreshModalHundler = ()=> {
      
      if(refreshModal==="1"){
        setRefreshModal('0');
      }else{
        setRefreshModal('1');
      }
      console.log('0 - refreched')
      
    }
    const onRefreshContainerHundler = ()=> {
      
      if(refreshContainer==="1"){
        setRefreshContainer('0');
      }else{
        setRefreshContainer('1');
      }
      console.log('0 - refreched')
      
    }
    const anotherWeekHundler = (weekNum) => {
     
     console.log("activated bias ->"+ weekNum)
      if(weekBias === undefined || weekBias === null){
       if(weekNum > 0 ) {
          setWeekBias(1);
        } else{
          setWeekBias(-1);
        }
      }else if(weekBias===0  ){
        if(weekNum > 0 ) {
          setWeekBias(1);
        } else{
          setWeekBias(-1);
        }
      }else{
        setWeekBias(weekBias + weekNum);
      }
         
    }
    const biasPlusHundler = (weekNum) => {
      if(weekBias === undefined || weekBias === null){
        setWeekBias(weekNum);  
       }else{
        setWeekBias(weekBias + weekNum);  
       }
        
     }
    const showCycled = () => {
   
      if(cycled===0){
        setCycled(1);
      }else{
        setCycled(0);
      }
      
      console.log("show cycled ->")
        
     }
     const biasMinusHundler = (weekNum) => {
      if(weekBias === undefined || weekBias === null){
        setWeekBias(weekNum*-1);  
       }else{
        setWeekBias(weekBias - weekNum);  
       }
     }
    const stopHund = (e) => {
      e.stopPropagation();
    }
    useEffect(()=>{
      if (headerHeight=== 1 &&  elementRef.current) {
        setHeaderContainer( elementRef.current.offsetHeight +48);
      }else if(headerHeight=== 2 &&  elementRef2.current){
        setHeaderContainer( elementRef2.current.offsetHeight +48);
      }else{
        setHeaderContainer('fit-content');
      }
    
    },[headerHeight,refreshContainer]);

    useEffect(()=>{
      
      if(searchParameters){
        setUrlSchedule( "/api/schedule?"+ searchParameters);
        setUrlInfopanel("/api/schedule/infopanel?" + searchParameters);
      }else{
        setUrlSchedule( "/api/schedule");
        setUrlInfopanel("/api/schedule/infopanel");
      }
    },[searchParameters]);

    useEffect(()=>{
      
      if(cycled===1){
        setSearchParameters( "cycled="+cycled);
        setInputs({
          'start':{'name':'start', 'type' : 'time','tagtype' : 'input', 'value' : '','disabled':false,'width':'10%'},
          'end':{'name':'end', 'type' : 'time','tagtype' : 'input', 'value' : '','disabled':false,'width':'10%'},
          'date':{'name':'date', 'type' : 'text','tagtype' : 'select','url':'/api/schedule?cycled=1', 'value' : '','disabled':false,'width':'10%'},
          'price':{'name':'price', 'type' : 'float','step':'0.01','tagtype' : 'input', 'value' : '','disabled':false,'width':'10%'},
          'duration':{'name':'duration', 'type' : 'float','step':'0.01','tagtype' : 'input', 'value' : '','disabled':false,'width':'20%'},
          'groupe':{'name':'groupe','type' : 'number','tagtype' : 'select','url':'/api/groups','value' : '','disabled':false,'width':'20%'},
          'isCycled':{'name':'isCycled','type' : 'number','tagtype' : 'select','url':'/api/booleans','value' : '1','disabled':true,'width':'10%'},
         });
      }else{
        setSearchParameters( "");
        setInputs({
          'start':{'name':'start', 'type' : 'time','tagtype' : 'input', 'value' : '','disabled':false,'width':'10%'},
          'end':{'name':'end', 'type' : 'time','tagtype' : 'input', 'value' : '','disabled':false,'width':'10%'},
          'date':{'name':'date', 'type' : 'date','tagtype' : 'input', 'value' : '','disabled':false,'width':'10%'},
          'price':{'name':'price', 'type' : 'float','step':'0.01','tagtype' : 'input', 'value' : '','disabled':false,'width':'10%'},
          'duration':{'name':'duration', 'type' : 'float','step':'0.01','tagtype' : 'input', 'value' : '','disabled':false,'width':'20%'},
          'groupe':{'name':'groupe','type' : 'number','tagtype' : 'select','url':'/api/groups','value' : '','disabled':false,'width':'20%'},
          'isCycled':{'name':'isCycled','type' : 'number','tagtype' : 'select','url':'/api/booleans','value' : '2','disabled':true,'width':'10%'},
         });
      }
    },[cycled]);

    useEffect(()=>{
      if(weekBias !== undefined && weekBias !== null ){
          setSearchParameters( "bias="+weekBias);
      
      }else{
        setSearchParameters("");
      }
    },[weekBias]);

    useEffect(()=>{
      onRefreshContainerHundler();
    },[urlSchedule,refreshModal]);

    // useEffect(()=>{
    //   onRefreshContainerHundler();
    // },[refreshContainer]);
  return (
    <Layout>
      <FunctionalHeader height={headerContainer} name={language[language.lang][(location.pathname.replace("/",""))]} >
      <Infopanel url={urlInfopanel} refresh={refreshContainer}  ></Infopanel>
      <input type="text" className='header-search-input' placeholder={language[language.lang].placeholderSearch} />
      <Function num={1} icon={<IconFilter/>} heightHundler={setHeaderHeight}  headerHeight={headerHeight} >
          <div key={1} ref={elementRef} style={{display:'flex',flexDirection:'row'}} >
            <SearchForm  setSearchParameters={setSearchParameters}  inputs={inputsSearch}  
            onRefreshHundler={onRefreshHundler} name={language[language.lang].modals[label].createTitle}></SearchForm>
            <div className='func-button' style={{marginTop:5,marginLeft:26,background: (cycled===1?'linear-gradient(45deg, #dc3fffa3 ,#6abcff99)':'transparent'),display:'flex'}} 
            onClick={(e) => showCycled()}>
              <p style={{whiteSpace:'nowrap',marginBlock:'auto',paddingBlock:6,paddingInline:10}}>Cycled Lessons</p>
              <IconCycled fillColor={'icon-red'} style={{padding:2,width:20,height:20 ,}}/>
            </div>
            <div className='func-button' style={{marginTop:5,marginLeft:5}} onClick={(e) => biasMinusHundler(1)}>
              <IconArrow style={{rotate:'180deg',width:20,height:20}}/>
            </div>
            <div className='func-button' style={{marginTop:5,marginLeft:6,marginRight:10}} onClick={(e) => biasPlusHundler(1)}>
              <IconArrow  style={{width:20,height:20}}/>
            </div>
          </div>
        </Function>
        <Function num={2} icon={<IconAdd/>} heightHundler={setHeaderHeight}  headerHeight={headerHeight} >
          <div key={2}  ref={elementRef2} >
            <Form   inputs={inputs} selectors={selectors} url={url} onRefreshHundler={onRefreshContainerHundler} 
            name={language[language.lang].modals[label].createTitle}></Form>
          </div>
        </Function>
        
      </FunctionalHeader>
    {/* <Header name={language[language.lang][(location.pathname.replace("/",""))]}   opened='300px' >
      <Form inputs={inputs} selectors={selectors} url={url} onRefreshHundler={onRefreshHundler} name={language[language.lang].modals[label].createTitle}></Form>
    </Header> */}
    <main className='main-block'  >
      <div className='main-content txt-c'  >
        <ScheduleContainer stateHundler={stateHundler} url={urlSchedule} refresh={refreshContainer} 
        onRefreshHundler={onRefreshHundler} />
      </div>  




      <ModalSchedule modalState={modalState} lessonid={toUpdateId} urlSchedule={urlSchedule}
      inputs={inputsToUpdate} refresh={refreshContainer}
      url={url} toUpdate={url+'/'+ toUpdateId }  onRefreshHundler={onRefreshModalHundler} 
      stateHundler={stateHundler} name={language[language.lang].modals[label].updateDescription} 
      title={language[language.lang].modals[label].updateTitle} />
      {/* <ModalSchedule modalState={modalState} inputs={inputsToUpdate} selectors={selectorsToUpdate} url={url} toUpdate={url+'/'+ toUpdateId } id={toUpdateId}  onRefreshHundler={onRefreshHundler} stateHundler={stateHundler} name={language[language.lang].modals[label].updateDescription} title={language[language.lang].modals[label].updateTitle} /> */}

    </main>

  </Layout>
  )
}

export default Schedule