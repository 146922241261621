import React, { useEffect, useState } from 'react'

export default function Function({children,icon,heightHundler,headerHeight,num}) {
   const [heigth, setHeight] = useState('fit-content');
   const [heigthMax, setHeightMax] = useState(0);
   const folder = {
      height:heigth,
      maxHeight:heigthMax,
      overflow:'hidden',
      position:'absolute',
      top:50,right:0,left:0, overflow:'hidden'
   }
   const heightHundl = () =>{
      if(headerHeight!==num){
         heightHundler(num);
         setHeightMax('fit-content');
      }else{
         heightHundler(0);
         setHeightMax(0);
      }
   }
   useEffect(()=>{
      if(headerHeight!==num){
         setHeightMax(0);
      }
   },[headerHeight])
  return (
    <div>
        <button className='func-button' onClick={heightHundl}>
            {icon}
       </button>
       <div style={folder}>
         {children}
       </div>
    </div>
  )
}
