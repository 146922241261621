import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import IconInfo from '../icons/IconInfo';

export const Infopanel = (props) => {
   const navigate = useNavigate();
   const [data, setData] = useState([]);
   useEffect(() => {
      
      axios.get(props.url, { withCredentials: true })
        .then(response => {
          setData(response.data);
        })
        .catch(err => {
          if(err.response.status === 401 ){
            console.log(err);
          //  navigate('/login');
          }else{
            console.log(err);
          }
          
        });
    }, [props.url,props.refresh]);


  return (
    <div className='peaces'>
      {data && data.length >0 && Array.isArray(data)? 
      ( data.map(item => (
          <div className='infopeac txt-c' key={item['id']+'pices'}> 
            <IconInfo fillColor={'icon-blue'} style={{marginBlock:'auto',height:20,width:20}} ></IconInfo> <p>{ item['name'] }:</p> <p>{ item['value'] }</p> 
          </div>
        ))
      
        ):
      ('')
       }
    </div>
  )
}
