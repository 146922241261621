import axios from 'axios';
import Layout from '../shared/Layout';
import { useContext, useEffect, useState } from 'react';
import DetailsContext from '../../providers/DetailsContext';
import language from '../../variables/language';
import { useLocation, Link, useParams  } from 'react-router-dom';
import Header from '../shared/Header';
import Form from '../../components/forms/Form';
import ActivityList from '../../components/settings/ActivityList';
import ModalUpdate from '../shared/ModalUpdate';
import ModalInterface from './ModalInterface';
import FlexList from '../../components/settings/FlexList';
const Answers = () => {
  // const [params, setParams] = useState({});
  // const API_URL = process.env.REACT_APP_API_URL;
  // const moduleName = 'firmy';
  const label = 'answers';
  const { id } = useParams();
  const params = '?conversation='+id;
  const url = '/api/answers';
  
 // const { setTheme } = useContext(DetailsContext);
 const location = useLocation();
 const [modalState ,setModalState] = useState('none');
 const [refresh, setRefresh] = useState(0);
 const [selectorRefresh, setSelectorRefresh] = useState(0);
 const [toUpdateId , setToUpdateId] = useState('');

 const [key, setKey] = useState(0);

 const reloadComponent = () => {
   setKey(prevKey => prevKey + 1); // Zmień klucz, aby wymusić przeładowanie
 };

 const [headers, setHeaders] = useState(
   {'text':{},
    'answerTypeId':{},
    'isLast':{},
    'icons':{
      'icon-delete':{'name':'icon-delete','type' : 'icon','url':url,'value' : '','mode':'delete'},
      'icon-edit':{'name':'icon-edit','type' : 'icon','url':url,'value' : '','mode':'edit'},}
    }
   );

   const [mixedinputs, setMixednputs] = useState(
    {

     'conversationId':{'name':'conversationId','type' : 'number', 'tagtype' : 'select','url':'/api/conversations','value' : id,'disabled':true,'width':'49%'},
     'answerTypeId':{'name':'answerTypeId','type' : 'number','tagtype' : 'select','url':'/api/answertypes','value' : '','disabled':false,'width':'39%'},
     'isLast':{'name':'isLast','type' : 'number','tagtype' : 'select','url':'/api/booleans','value' : '','disabled':false,'width':'9%'},
     'text':{'name':'text', 'type' : 'textarea','tagtype' : 'textarea', 'value' : '','disabled':false,'width':'99%'},
    });

    
   const [mixedinputsToUpdate, setMixednputsToUpdate] = useState(
    {

     'answerTypeId':{'name':'answerTypeId','type' : 'number','tagtype' : 'select','url':'/api/answertypes','value' : '','disabled':false,'width':'49%'},
     'isLast':{'name':'isLast','type' : 'number','tagtype' : 'select','url':'/api/booleans','value' : '','disabled':false,'width':'49%'},
     'text':{'name':'text', 'type' : 'textarea','tagtype' : 'textarea', 'value' : '','disabled':false,'width':'99%'},
    });


 const [inputs, setInputs] = useState(
   {
    'text':{'name':'text','type' : 'textarea','value' : ''},
    'point':{'name':'point','type' : 'number','value' : ''},
   });
 const [selectors, setSelectors] = useState({
    'conversationId':{'name':'conversationId','type' : 'number','url':'/api/conversations','value' : id,'disabled':true},
    'answerTypeId':{'name':'answerTypeId','type' : 'number','url':'/api/answertypes','value' : ''},
    // 'estimationGroup':{'name':'estimationGroup','type' : 'number','url':'/api/estimationgroup'+params,'value' : ''},
    'isLast':{'name':'isLast','type' : 'number','url':'/api/booleans','value' : ''},
   });
 const [inputsToUpdate, setInputsToUpdate] = useState({
    'text':{'name':'text','type' : 'text','value' : ''},
   });
 const [selectorsToUpdate, setSelectorsToUpdate] = useState({
    'answerTypeId':{'name':'answerTypeId','type' : 'number','url':'/api/answertypes','value' : ''},
    'conversationId':{'name':'conversationId','type' : 'number','url':'/api/conversations','value' : id,'disabled':true},
    // 'estimationGroup':{'name':'estimationGroup','type' : 'number','url':'/api/estimationgroup'+params,'value' : ''},
    'isLast':{'name':'isLast','type' : 'number','url':'/api/booleans','value' : ''},
   });

 

   const stateHundler = (state,id) => {
     if(state === 'edit'){
       setModalState('edit');
       console.log("!!! stateHundler edit ->"+id);
       setToUpdateId(id);
     }else if(state === 'none'){
       setModalState('none');
     }else if(state === 'nextedit' || state === 'fromedit'){
      setModalState('none');
      setModalState('edit');
      setToUpdateId(id);
     }
   }
   const onRefreshHundler = ()=> {
     if(refresh==="1"){
       setRefresh('0');
     }else{
       setRefresh('1');
     }
  //  reloadComponent();
     console.log("refresh in answer --{")
   }


return (
 <Layout>
   <Header name={language[language.lang][label]}   opened='300px' >
     <Form inputs={mixedinputs}  url={url} 
     onRefreshHundler={onRefreshHundler} 
     name={language[language.lang].modals[label].createTitle}></Form>
   </Header>
   <main className='main-block' >
     <div className='main-content txt-c' >
     <FlexList name=''
     table='table' 
     thead='table-header-bot-ans' htr='table-h-tr-bot-ans' 
     th='table-h-th-bot-ans ' tbody='table-body' 
     btr='table-b-tr-bot-ans' td='table-b-td-bot-ans' 
      url={url+params} done={refresh} stateHundler={stateHundler} onRefreshHundler={onRefreshHundler} headers={headers}/>
     </div>  
     <ModalInterface key={key} modalState={modalState} inputs={mixedinputsToUpdate}  
    url={url} toUpdate={url+'/'+ toUpdateId }  
     onRefreshHundler={onRefreshHundler} stateHundler={stateHundler} 
     answerId={toUpdateId} convParams={params}
     name={language[language.lang].modals[label].updateDescription} 
     title={language[language.lang].modals[label].updateTitle} />
   </main>

 </Layout>
)
}

export default Answers