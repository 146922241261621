import  {useState ,useEffect} from 'react'
import language from '../../variables/language';
import '../../css/form.css'
import Selector from '../shared/Selector';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Feedback from '../shared/Feedback';
import FlexList from '../../components/settings/FlexList';
import StudentsConversation from './StudentsConversations';
export default function FormStudent(props) {
   const [errMsg, setErrMsg] = useState('');
   const [visibility, setVisibility] = useState('flex');
   const [status, setStatus] = useState(props.status);
   const [inputs,setInputs] = useState(props.inputs);
   const navigate = useNavigate();
   const [stages, setStages] = useState([]);
   const [stage, setStage] = useState('none');
   const [stageColor, setStageColor] = useState('white');
   const [refresh, setRefresh] = useState(0);
   const progressUrl = "/api/conversationprogress";
   const byConverstionParams = "?student="+props.id;


   const handleInputChange = (event, fieldName) => {
      const updatedInputs = { ...inputs };
      updatedInputs[fieldName].value = event.target.value;
      
      setInputs(updatedInputs);
    
      
  };
  const selectorsHundler = ( selectors) =>{
   const updatedInputs = { ...inputs };
   if (updatedInputs.hasOwnProperty(selectors.name)) {
      // Jeśli pole już istnieje, aktualizujemy jego wartość
      updatedInputs[selectors.name].value = selectors.value;
  } else {
      // Jeśli pole nie istnieje, dodajemy nowe pole do obiektu inputs
      updatedInputs[selectors.name] = {
          value: selectors.value
      };
  }
      // // console.log("selectors -> "+selectors.value );
      // // console.log("Name -> "+selectors.name );
   setInputs(updatedInputs);

  }

  const [headersProgressConv, setHeadersProgressConv] = useState(
   {'outerUserId':{'name':'username'},
   'conversationId':{},
    'isFinished':{},
    'icons':{
      'icon-messages':{'name':'icon-messages','type' : 'icon','url':'/messages/byconvprogress','value' : '','mode':'card'},
      }
    }
   );

  
   
   
   const submitHundler =(e)=>{
      e.preventDefault();
      const formData = {};
         // Zbieranie wartości z pól formularza
        // const inputsTosave = document.querySelectorAll('.submit-field');
        Object.keys(inputs).forEach(fieldName => {
           formData[fieldName] = inputs[fieldName].value;
         //  // console.log(inputs[fieldName].name+ '<- is ->' + formData[fieldName]);
         });
        // // console.log('end hundler -> size of inputs: '+Object.keys(inputs).length);
        if(props.mode === 'edit'){
         axios.put(props.url,
            JSON.stringify(formData), {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        ).then(response => {
           statusHundler('done');
        })
        .catch(err => {
          if (err.response) {
            if(err.response.status===403){
               statusHundler('forbidden');
            }else if(err.response.status=== 500){
               statusHundler('error');
            } else if(err.response.status === 401 ){
               navigate('/login');
            }
         } else {
             // console.log('Błąd podczas wysyłania żądania:', err.message);
         }
        });
        setStatus('wait');
        }else{
         axios.post(props.url,
            JSON.stringify(formData), {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        ).then(response => {
           statusHundler('done');
        })
        .catch(err => {
          if (err.response) {
            
             if(err.response.status===403){
                statusHundler('forbidden');
             }else if(err.response.status=== 500){
                statusHundler('error');
             } else if(err.response.status === 401 ){
                navigate('/login');
             }
         } else {
             // console.log('Błąd podczas wysyłania żądania:', err.message);
         }
        });
        setStatus('wait');
        }
         
   }
   const statusHundler=(status)=>{
      setStatus(status);
   }
   //status of form updater 
    useEffect(() => {
      if(status === 'none'){
         setVisibility('flex');
      }else if(status === 'wait'){
         setVisibility('none');
      }else if(status === 'done'){
         setVisibility('none');
         props.onRefreshHundler();
      }else if(status === 'error'){
         setVisibility('none');
      }else if(status === 'forbidden'){
         setVisibility('none');
      }else if(status === 'timeout'){
         setVisibility('none');
      }
    }, [status,props.status]);
    useEffect(()=>{
      setStatus(props.status);
    },[props.status])

    //init function sets inputs selectors to one data
    useEffect(() => {
      const updatedInputs = { ...inputs };
      Object.keys(props.selectors).forEach(fieldName => {
         if (inputs.hasOwnProperty(props.selectors[fieldName])) {
            updatedInputs[props.selectors[fieldName].name].value = props.selectors[fieldName].value;
            updatedInputs[props.selectors[fieldName].name].name = props.selectors[fieldName].name;
         } else {
            updatedInputs[props.selectors[fieldName].name] = {
               'value': props.selectors[fieldName].value,
               'name': props.selectors[fieldName].name
            };
         }
      });
      // Object.keys(props.inputs).forEach(fieldName => {
      //    if (inputs.hasOwnProperty(props.inputs[fieldName])) {
      //       updatedInputs[props.inputs[fieldName].name].value = props.inputs[fieldName].value;
      //       updatedInputs[props.inputs[fieldName].name].name = props.inputs[fieldName].name;
      //    } else {
      //       updatedInputs[props.inputs[fieldName].name] = {
      //          'value': props.inputs[fieldName].value,
      //          'name': props.inputs[fieldName].name
      //       };
      //    }
      // });
      setInputs(updatedInputs);

   }, [props.selectors,props.inputs,props.data]);
   // useEffect(() => {
   //    axios.get('/api/stages', { withCredentials: true })
   //      .then(response => {
   //         setStages(response.data);
   //         // console.log('worked ----');
   //         // console.log(' worked ---- inputs json'+ JSON.stringify(inputs, null, 2) );
   //         if(props.selectors && response.data && props.selectors['stage'] && typeof props.selectors['stage'] === 'object' && props.selectors['stage']['value'] ){
   //           // console.log("value or id ................>>>"+ props.selectors['stage'].value);
   //             setStage(response.data.find(stage =>  stage.id === props.selectors['stage'].value).name);
   //             setStageColor(response.data.find(stage =>  stage.id === props.selectors['stage'].value).color);
               
   //         }
   //       //  // console.log('my own data inputs->'+ JSON.stringify(inputs, null, 2));
          
   //      })
   //      .catch(err => {
   //       //  if(err.response.status === 401 ){
   //       //    navigate('/login');
   //       //  }
   //        // console.log(err);
   //      });
   //  }, [ status,props.selectors,props.data]);
   const onRefreshHundler = ()=> {
      if(refresh==="1"){
        setRefresh('0');
      }else{
        setRefresh('1');
      }
      
    }

    const stateHundler = (state,id) => {
      if(state === 'edit'){
      //   setModalState('edit');
      //   console.log("ID:"+id);
      //   setToUpdateId(id);
      }else if(state === 'none'){
       // setModalState('none');
      }
    }
  return (
    <div style={{width:'100%'}}>
 
      <div  style={{display:visibility,flexDirection:'column'}}>
     
         <div className='modal-client-header-container'  data-label={inputs['name'].value.charAt(0)+inputs['surname'].value.charAt(0)} >
      
         <svg style={{paddingInline:5}} xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 0 24 24" width="40px" fill="#e8eaed"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 3 1 9l4 2.18v6L12 21l7-3.82v-6l2-1.09V17h2V9L12 3zm6.82 6L12 12.72 5.18 9 12 5.28 18.82 9zM17 15.99l-5 2.73-5-2.73v-3.72L12 15l5-2.73v3.72z"/></svg>
            <div className='m-c-h-c-container'>
               <div className='price-modal-client'>{inputs['telegramUsername'].value} </div>
               <h4 className='modal-client-header-container-txt txt-c' style={{marginTop:1}} >{inputs['name'].value +"  "+inputs['surname'].value}</h4>


            </div>
           <div className='container-line'>

               
            </div> 

         </div>
         <div style={{display:'flex',flexWrap:'wrap',justifyContent:'start'}}>
            <form className={`' ${props.clasContainer} txt-c'`}>
               {/* inputs */}
               {Object.keys(props.inputs).map(field => (
                  <div className={`${props.clasWrapper}`} data-label={language[language.lang][props.inputs[field].name]} key={props.inputs[field].name}>
                     <input className={`submit-field ${props.clasInputs}  txt-c`}  required={true}
                     placeholder={language[language.lang][props.inputs[field].name]} 
                     key={props.inputs[field].name + 'inp'}
                     type={props.inputs[field].type}
                     name={props.inputs[field].name}
                  
                     value={inputs[field].value}
                     onChange={(e) => handleInputChange(e, inputs[field].name)}
                     data-label={language[language.lang][props.inputs[field].name]} />
                  </div>
               ))}
         
               {/* selects */}
               {Object.keys(props.selectors).map(field => (
                  <div className={props.clasWrapper} key={props.selectors[field].name}  data-label={language[language.lang][props.selectors[field].name]}>
                     <Selector className={`submit-field ${props.clasSelectors} txt-c`} key={'sel-'+props.selectors[field].name} selectorsHundler={selectorsHundler} value={props.selectors[field].value} name={props.selectors[field].name} url={props.selectors[field].url} /> 
                  </div>
               ))}
         
               {/* submit button */}
               <button type='submit' className=' form-button txt-c' onClick={submitHundler}>
                  {language[language.lang].save}
               </button>
            </form>
            <div className={`' ${props.clasContainer} '`} style={{borderRight:'0px solid transparent',minWidth:'49%'}}>
           
           <StudentsConversation id={props.id}></StudentsConversation>

            {/* <FlexList name='' table='table' 
               thead='table-header' htr='table-h-tr' 
               th='table-h-th-bot-con ' tbody='table-body' 
               btr='table-b-tr' td='table-b-td' 
               url={progressUrl+byConverstionParams} done={refresh} stateHundler={stateHundler} onRefreshHundler={onRefreshHundler} headers={headersProgressConv}/>
                */}
            </div>
         </div>
         
         


         
     
      </div>
      <Feedback statusHundler={statusHundler} status={status} />
      
    </div>
  )
}
