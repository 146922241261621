import { useEffect, useState } from 'react';
import Table from '../tables/Table';
import axios from 'axios';
import {  useNavigate } from 'react-router-dom';

export default function FlexList(props) {
   const navigate = useNavigate();
  const [headers, setHeaders] = useState(props.headers);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (props.headers) {
      setHeaders(props.headers);
    }
    setData(0);
    axios.get(props.url, { withCredentials: true })
      .then(response => {
        setData(response.data);
      })
      .catch(err => {
        if(err.response.status === 401 ){
          console.log(err);
          navigate('/login');
        }else{
          console.log(err);
        }
        
      });
  }, [props.headers, props.url, navigate,props.done]);
  return (
    <div style={{width:'100%'}}>
      <h3>{props.name}</h3>
      <Table headers={headers} 
      label={props.label}  
      onRefreshHundler={props.onRefreshHundler} 
      stateHundler={props.stateHundler} 
      table={props.table} 
      thead={props.thead} htr={props.htr}
      th={props.th} tbody={props.tbody} btr={props.btr} td={props.td} data={data} />
    </div>
  )
}
