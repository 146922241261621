import  { useState ,useEffect} from 'react'
import Panels from '../../components/func panel/Panels'
import Search from '../../components/func panel/Search'

export default function InnerHeader( {children,name,opened,done}) {
   const [heigth, setHeight] = useState('fit-content');
   const [heigthMax, setHeightMax] = useState(0);
   const folder = {
      height:heigth,
      maxHeight:heigthMax,
      overflow:'hidden',
   }
   const heightHundler = () =>{
      if(heigthMax===0){
         setHeightMax('fit-content');
      }else{
         setHeightMax(0);
      }
   }
   //  useEffect(() => {
   //    setHeightMax(done)
   //  }, [done]);
  return (
   <header  className='inner-header-block'>
      <div className='header-panel txt-c'>
            <div className='header-txt ' >{name}</div>
            <Panels>
              
            </Panels>
            <div className='func-container'>
              <button className='func-button' onClick={heightHundler}>
                  <svg xmlns="http://www.w3.org/2000/svg" className='search-icon' viewBox="0 -960 960 960" ><path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"/></svg>
              </button>
            </div>
      </div>
      <div className='folder-container' style={folder}>
          {children}
      </div>
   
   </header>
  )
}
