import React, { useEffect, useState } from 'react'
import {  useNavigate } from 'react-router-dom';
import Icon from '../../components/tables/Icon';
import axios from 'axios';
import IconCycled from '../../components/icons/IconCycled';
import IconStudent from '../../components/icons/IconSudent';

export default function LessonCard(props) {
   const urlParticipants = '/api/events?lesson='+ props.id;
   
   const [participans,setParticipans] =  useState([]);
   const navigate = useNavigate(); 
   const [display , setDisplay ] = useState('block');
   const [displayStages , setDisplayStages ] = useState('none');
   const [displayCard , setDisplayStagesCard ] = useState('block');
   const [fixed , setFixed ] = useState('static');
   const [startHour, startMinute] = props.start.split(':').map(Number);
   const now = new Date();
   const currentHour = now.getHours();
   const currentMinute = now.getMinutes();

   useEffect(()=>{
    
      axios.get(urlParticipants, { withCredentials: true })
      .then(response => {
        setParticipans(response.data);
      })
      .catch(err => {
        console.log(err);
      });
   },[props.data])

   const oncliHundler =(e)=> {
   
      if(display=='none'){
        // setDisplay('block');
        // setDisplayStages('none');
         
      }else{
       //  setDisplay('none');
         props.stateHundler('menu',props.id);
      }
   }
   const changeStage = (e) => {
      e.stopPropagation();
      if(displayStages==='none'){
         setDisplayStages('flex');
      }else{
         setDisplayStages('none');
      }
     
   }
   

   const setStage = (e,id) => {
      e.stopPropagation();
      setDisplayStages('none');
      
      axios.put('/api/clients/'+props.id,
         JSON.stringify({stage:id}), {
            withCredentials: true,
            headers: {
               'Content-Type': 'application/json',
            },
      }).then(response => {
        props.onRefresh();
        setDisplayStagesCard('none');
      })
      .catch(err => {
         navigate('/pipeline');
      if(err.response.status === 401 ){
         navigate('/login');
      }
      console.log(err);
      });
   }
   const goToClient = (e) => {
      e.stopPropagation();
      navigate("/clients/"+props.id);
   }
   
  return (
    <div className='pipe-card' onClick={oncliHundler} 
     style={{display:displayCard,background: ( (props.today > props.date) || ( (props.today === props.date) && (currentHour > startHour ) ))?
      'linear-gradient(45deg, rgb(31 245 236 / 21%), rgb(14 134 58 / 48%))':'linear-gradient(45deg, #4900ff45, #ff00103d)' ,
      borderBottom:`1px solid black`, borderRadius:6, marginBlock:17,marginInline:7}} >
      <div style={{display:'block'}}>
         <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',position:'absolute',top:-20,left:3, right:3}}>
            <h4 className='pipe-card-title' style={{textAlign:'left'}}>{props.title}</h4>
            <h5 style={{color:'#667eff',marginBlock:3,}} >{props.duration} min</h5>
         </div>
         { props.item.isCycled === 1 ? (
            <div style={{position:'absolute', top:-20,right:50,marginInline:'auto'}}>
               <IconCycled fillColor='icon-red' style={{height:15,width:15}}/>
            </div>
            
         ) : (
            ''
         ) }
         
         <div style={{display:'flex',flexWrap:'wrap'}}>
         { participans && Array.isArray(participans) ? (
                  participans.map(item => (
                     <div key={item.id+'sd'}  style={{borderRadius:3,display:'flex',flexDirection:'row',
                     border:`1px solid ${ item.attendence===1 ? '#0ca200ab': item.attendence===0 ?'#8080807a': 'rgb(255 81 25 / 71%)' } `,
                     borderLeft:`9px solid ${ item.attendence===1 ? '#0ca200ab': item.attendence===0 ?'#8080807a': 'rgb(255 81 25 / 71%)' } `,
                    
                     padding:2,marginInline:3,fontSize:12,letterSpacing:2,marginBlock:4, paddingInline:5}} className=' txt-c' >
                        <IconStudent fillColor={'icon-blue'} style={{width:15,height:15,marginBlock:'auto',marginRight:8}} /> <p style={{fontWeight:600}}>{' '+item.student.surname +' '+item.student.name.charAt(0)}</p> 
                        </div>
                  ))
               ) : (
                  <div>No Students yet</div>
            )}
         </div>
         <div  style={{position:'absolute',filter:'blur(4px)',left:0,top:0,bottom:0, width:'0%', height:'100%'}}>
            <div 
            style={{ height:`0%`,width:'100%',
            background: ( (props.today > props.date) || ( (props.today === props.date) && (currentHour > startHour ) ))?'green':'transparent' }}></div>
         </div>
         <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}} >
            <p  className=' txt-c' style={{fontSize:13}} >{props.price} zł / h</p>
            <p  className=' txt-c' style={{color:'#ff5f2d', fontSize:13,fontWeight:800}} >to pay - { participans ?  ( ((props.price / 60) * props.duration ) * participans.filter(participant => participant.toPay === 1 || participant.toPay === 0).length ) : '0' } zł   </p>
         </div>
      </div>
      <div className='card-icons' style={{display: (display==='none')? 'flex':'none'}}>
         <div className='card-btn-goto' onClick={goToClient} ></div>
         <div className='card-btn-stage' style={{width:100}} onClick={changeStage} ></div>
      </div>
      {/* <div className='card-pipe-selector' style={{display:displayStages}}>
         {Array.isArray(props.stages) ? (
                  props.stages.map(item => (
                     <div key={item.id+'sd'} onClick={ (e) => setStage(e, item.id) } style={{borderLeft:`3px solid ${item.color}`,boxShadow:` 0px 0px 3px ${item.color}`}} className='card-selector-stage txt-c' >{item.name}</div>
                  ))
               ) : (
                  <div>Empty list</div>
            )}
      </div> */}
    </div>
  )
}
