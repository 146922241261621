import { useEffect, useState } from 'react';
import '../../css/modals.css';
import ModalWindow from '../shared/ModalWindow';
import axios from 'axios';
import {  useNavigate } from 'react-router-dom';
import WindowInterface from './FormInterface';
import FormInterface from './FormInterface';
import ActivityList from '../../components/settings/ActivityList';
import FlexList from '../../components/settings/FlexList';
import Header from '../shared/Header';
import Form from '../../components/forms/Form';
import InnerHeader from './InnerHeader';
export default function ConversationUpdate(props) {
   const navigate = useNavigate();
   const [visibility, setVisibility] = useState('none');
   const labelFrom = 'fromId';
   const labelNext = 'nextId';
   const [modalState ,setModalState] = useState('none');
   const [refresh, setRefresh] = useState(0);
   const [toUpdateId , setToUpdateId] = useState(props.answerId);
   const [inputs, setInputs] = useState({...props.inputs });
   const [data, setData] = useState([]);
   const url = "/api/estimationgroup";
   const mainUrl = "/api/conversations";
   const progressUrl = "/api/conversationprogress";
   const byConverstionParams = "?conversation="+props.answerId;
   const paramsFrom = "?fromId="+props.answerId;
   const modalHideHundler = () => {
      setVisibility('none');
      props.stateHundler('none');
   }
   const stopProp = (event) => {
      event.stopPropagation();
   }



   const [headersEstimationG, setHeadersEstimationG] = useState(
         {'id':{},
          'name':{},
          'icons':{
            'icon-delete':{'name':'icon-delete','type' : 'icon','url':url,'value' : '','mode':'delete'},}
          }
         );
   const [headersProgressConv, setHeadersProgressConv] = useState(
            {'outerUserId':{'name':'name','name1':'surname','name2':'username'},
             'isFinished':{},
             'meta':{},
             'icons':{
               'icon-messages':{'name':'icon-messages','type' : 'icon','url':'/messages/byconvprogress','value' : '','mode':'card'},
               }
             }
            );
   const [mixedinputs, setMixednputs] = useState(
            {
             'conversation':{'name':'conversation','type' : 'number','tagtype' : 'select','url':'/api/conversations','value' : props.answerId,'disabled':true,'width':'39%'},
         
             'name':{'name':'name','type' : 'text', 'tagtype' : 'input','disabled':false,'width':'59%'},
            });
  
   useEffect(()=>{
      if(props.modalState === 'edit'){
         axios.get(props.toUpdate, { withCredentials: true })
            .then(response => {
               setData(response.data);
               console.log("modal update data +>" +JSON.stringify(data, null, 2));
               
            })
            .catch(err => {
               if(err.response.status === 401 ){
                  console.log(err);
                  navigate('/login');
                }else{
                  console.log(err);
                }
            });
         setVisibility('block');
      }
      
   },[props.modalState,props.inputs,props.onRefreshHundler]);
   useEffect(()=>{
      onRefreshHundler();
      setInputs({...props.inputs });
      setToUpdateId(props.answerId);
      console.log("*******************************refreshes --------------------"+props.answerId);
      setMixednputs({
               'conversation':{'name':'conversation','type' : 'number','tagtype' : 'select','url':'/api/conversations','value' : toUpdateId,'disabled':true,'width':'39%'},
             'name':{'name':'name','type' : 'text', 'tagtype' : 'input','disabled':false,'width':'59%'},
        });
        
   },[props.inputs,props.onRefreshHundler,props.answerId,props.refresh])

   const onRefreshHundler = ()=> {
      if(refresh==="1"){
        setRefresh('0');
      }else{
        setRefresh('1');
      }
      setToUpdateId(props.answerId);
      
    }

    const stateHundler = (state,id) => {
      if(state === 'edit'){
        setModalState('edit');
        console.log("ID:"+id);
        setToUpdateId(id);
      }else if(state === 'none'){
        setModalState('none');
      }
    }
  return (
    <div className='modal-update' onClick={modalHideHundler} style={{display:visibility}} >
        <div className='modal-window' onClick={stopProp}>
            <div style={{display:'flex',flexDirection:'row'}}>
               <h3 className='modal-w-title txt-c' style={{marginTop:20,marginBlock:10,paddingTop:10,borderTop:'1px solid #8080802e',width:'100%',textAlign:'center'}} >Conversation  </h3>
                  <svg style={{paddingInline:5}} xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 0 24 24" width="40px" fill="#e8eaed"><path d="M0 0h24v24H0V0z" fill="none"/><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><path d="M20,9V7c0-1.1-0.9-2-2-2h-3c0-1.66-1.34-3-3-3S9,3.34,9,5H6C4.9,5,4,5.9,4,7v2c-1.66,0-3,1.34-3,3s1.34,3,3,3v4 c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-4c1.66,0,3-1.34,3-3S21.66,9,20,9z M18,19L6,19V7h12V19z M9,13c-0.83,0-1.5-0.67-1.5-1.5 S8.17,10,9,10s1.5,0.67,1.5,1.5S9.83,13,9,13z M16.5,11.5c0,0.83-0.67,1.5-1.5,1.5s-1.5-0.67-1.5-1.5S14.17,10,15,10 S16.5,10.67,16.5,11.5z M8,15h8v2H8V15z"/></g></g></svg>
            </div>
            
            {/* <FlexList name='' table='table' 
               thead='table-header-bot-con' htr='table-h-tr-bot-con' 
               th='table-h-th-bot-con ' tbody='table-body' 
               btr='table-b-tr-bot-con' td='table-b-td-bot-con'  url={url+paramsNext} done={refresh} stateHundler={props.stateHundler} onRefreshHundler={onRefreshHundler} headers={headersByNext}/>
            */}
            <FormInterface inputs={props.inputs} url={mainUrl+"/"+props.answerId} data={data} onRefreshHundler={props.onRefreshHundler}  name={props.name} title={props.title} />
            <div style={{display:'flex',flexDirection:'row'}}>
               <h3 className='modal-w-title txt-c' style={{marginTop:20,marginBlock:10,paddingTop:10,borderTop:'1px solid #8080802e',width:'100%',textAlign:'center'}} >Estimation groups </h3>

                  <svg style={{paddingInline:5}} xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 0 24 24" width="40px" fill="#e8eaed"><g><rect fill="none" height="24" width="24"/></g><g><g><path d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M19,19H5V5h14V19z"/><rect height="1.5" width="5" x="6.25" y="7.72"/><rect height="1.5" width="5" x="13" y="15.75"/><rect height="1.5" width="5" x="13" y="13.25"/><polygon points="8,18 9.5,18 9.5,16 11.5,16 11.5,14.5 9.5,14.5 9.5,12.5 8,12.5 8,14.5 6,14.5 6,16 8,16"/><polygon points="14.09,10.95 15.5,9.54 16.91,10.95 17.97,9.89 16.56,8.47 17.97,7.06 16.91,6 15.5,7.41 14.09,6 13.03,7.06 14.44,8.47 13.03,9.89"/></g></g></svg>
            </div>
            <InnerHeader name='Add new estimation group'    opened='300px' >
               <Form inputs={mixedinputs}  url={url} refresh={refresh}
               onRefreshHundler={onRefreshHundler} 
               name=''></Form>
            </InnerHeader>
            <FlexList name='' table='table' 
               thead='table-header-bot-con' htr='table-h-tr-bot-con' 
               th='table-h-th-bot-con ' tbody='table-body' 
               btr='table-b-tr' td='table-b-td' 
                url={url+byConverstionParams} done={refresh} stateHundler={props.stateHundler} onRefreshHundler={onRefreshHundler} headers={headersEstimationG}/>
             <div style={{display:'flex',flexDirection:'row'}}>
               <h3 className='modal-w-title txt-c' style={{marginTop:20,marginBlock:10,paddingTop:10,borderTop:'1px solid #8080802e',width:'100%',textAlign:'center'}} >Chats within this conversation  </h3>

                  <svg style={{paddingInline:5}} xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 0 24 24" width="40px" fill="#e8eaed"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M15 4v7H5.17L4 12.17V4h11m1-2H3c-.55 0-1 .45-1 1v14l4-4h10c.55 0 1-.45 1-1V3c0-.55-.45-1-1-1zm5 4h-2v9H6v2c0 .55.45 1 1 1h11l4 4V7c0-.55-.45-1-1-1z"/></svg>
            </div>
            <FlexList name='' table='table' 
               thead='table-header-bot-con' htr='table-h-tr-bot-con' 
               th='table-h-th-bot-con ' tbody='table-body' 
               btr='table-b-tr' td='table-b-td' 
                url={progressUrl+byConverstionParams} done={refresh} stateHundler={props.stateHundler} onRefreshHundler={onRefreshHundler} headers={headersProgressConv}/>
        </div>
    </div>
  )
}
