import React, { useState } from 'react'
import {  useNavigate } from 'react-router-dom';
import Icon from '../../components/tables/Icon';
import axios from 'axios';

export default function ScheduleMenu(props) {
   const navigate = useNavigate(); 
   const [display , setDisplay ] = useState('block');
   const [displayStages , setDisplayStages ] = useState('none');
   const [displayCard , setDisplayStagesCard ] = useState('block');
   const [fixed , setFixed ] = useState('static');



   const oncliHundler =(e)=> {
   
      if(display=='none'){
        // setDisplay('block');
        // setDisplayStages('none');
         
      }else{
       //  setDisplay('none');
         props.stateHundler('none',props.id);
      }
   }
   const changeStage = (e) => {
      e.stopPropagation();
      if(displayStages==='none'){
         setDisplayStages('flex');
      }else{
         setDisplayStages('none');
      }
     
   }
   

   const setStage = (e,date) => {
      e.stopPropagation();
      axios.put('/api/lessons/'+props.id,
         JSON.stringify({date:date}), {
            withCredentials: true,
            headers: {
               'Content-Type': 'application/json',
            },
      }).then(response => {
        props.onRefresh();
        props.stateHundler('none',props.id);
      })
      .catch(err => {
         //navigate('/schedule');
      
      console.log(err);
      });
   }
   const goToClient = (e) => {
      e.stopPropagation();
      navigate("/lessons/"+props.id);
   }
   const onRefreshHundler = ()=>{

   }
  return (
    <div style={{height:'100%'}}  onClick={oncliHundler}   >
      
   
      <div className='menu-calendar-selector' style={{height:'100%'}} >
         {Array.isArray(props.stages) ? (
                  props.stages.map(item => (
                     <div key={item.id} onClick={ (e) => setStage(e, item.date) } 
                     style={{borderLeft:`3px solid ${item.color}`,cursor:'pointer', boxShadow:` 0px 0px 3px ${item.color}`,display:'flex' ,background:'#54f79275',height:'100%' }} 
                     className='card-selector-stage txt-c' > <p style={{marginBlock:'auto'}}>{item.dayOfWeek}</p> </div>
                  ))
               ) : (
                  <div>Empty list</div>
            )}
      </div>
    </div>
  )
}
