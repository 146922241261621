import { useEffect, useState } from 'react';
import '../../css/modals.css';
import axios from 'axios';
import {  useNavigate } from 'react-router-dom';
import FormInterface from '../conversations/FormInterface';
export default function ModalUpdate(props) {
   const navigate = useNavigate();
   const [visibility, setVisibility] = useState('none');
 
   const [modalState ,setModalState] = useState('none');
   const [refresh, setRefresh] = useState(0);
   const [toUpdateId , setToUpdateId] = useState(props.answerId);
   const [inputs, setInputs] = useState({...props.inputs });
   const [data, setData] = useState([]);
   const [url , setUrl] = useState(props.url);
   const urlAnswer = '/api/events/'+ toUpdateId;
   const modalHideHundler = () => {
      setVisibility('none');
      props.stateHundler('none');
   }
   const stopProp = (event) => {
      event.stopPropagation();
   }


  
   useEffect(()=>{
      if(props.modalState === 'edit'){
         axios.get(urlAnswer, { withCredentials: true })
            .then(response => {
               setData(response.data);
               console.log("modal update data +>" +JSON.stringify(data, null, 2));
               
            })
            .catch(err => {
               if(err.response.status === 401 ){
                  console.log(err);
                  navigate('/login');
                }else{
                  console.log(err);
                }
            });
         setVisibility('block');
      }
      
   },[props.modalState,props.inputs,props.onRefreshHundler]);
   useEffect(()=>{
      setInputs({...props.inputs });
      setToUpdateId(props.answerId);
      onRefreshHundler();
   },[props.inputs,props.onRefreshHundler,props.answerId])

   const onRefreshHundler = ()=> {
      if(refresh==="1"){
        setRefresh('0');
      }else{
        setRefresh('1');
      }
      setToUpdateId(props.answerId);
      
    }


    const stateHundler = (state,id) => {
      if(state === 'edit'){
        setModalState('edit');
        console.log("ID:"+id);
        setToUpdateId(id);
      }else if(state === 'none'){
        setModalState('none');
      }
    }
  return (
    <div className='modal-update' onClick={modalHideHundler} style={{display:visibility}} >
        <div className='modal-window' onClick={stopProp}>
          
            <FormInterface inputs={props.inputs} url={urlAnswer} data={data} onRefreshHundler={props.onRefreshHundler}
              name={props.name} title={props.title} />
          
           

        </div>
    </div>
  )
}
