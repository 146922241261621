import axios from 'axios';
import Layout from '../shared/Layout';
import Form from '../shared/Form';
import { useContext, useEffect, useState } from 'react';
import DetailsContext from '../../providers/DetailsContext';
import ActivityList from '../../components/settings/ActivityList';
import language from '../../variables/language';
import { useLocation, Link  } from 'react-router-dom';

import Header from '../shared/Header';
import ModalUpdate from '../shared/ModalUpdate';
import "../../css/grid.css"
const Games = () => {

  const label = 'games';
  const location = useLocation();



  return (
    <Layout>
      <Header name={language[language.lang][(location.pathname.replace("/",""))]}   opened='300px' >
        
      </Header>
      <main className='main-block' >
        <div className='main-content txt-c' >
          <div className='flex-wrapper'>
            <div className='flex-elem grid-card'  >
              <Link  to='/grid'>
                <img src="/grid.webp" alt="grid" className='flex-img' />
                <p className='grid-txt'>Grid Numbers</p>
              </Link>
            </div>

            <div className='flex-elem grid-card'  >
              <Link  to='/quiz'>
                <img src="/quiz.webp" alt="grid" className='flex-img' />
                <p className='grid-txt'>Quiz</p>
              </Link>
            </div>

            <div className='flex-elem grid-card'  >
              <Link  to='/letters'>
                <img src="/word.webp" alt="letters" className='flex-img' />
                <p className='grid-txt'>Letters</p>
              </Link>
            </div>

            <div className='flex-elem grid-card'  >
              <Link  to='/matching'>
                <img src="/matching.webp" alt="matching" className='flex-img' />
                <p className='grid-txt'>Matching</p>
              </Link>
            </div>
          </div>
        </div>  
       </main>

    </Layout>
  )
}

export default Games