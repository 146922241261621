import { useEffect, useState } from 'react';
import axios from 'axios';
import {  useNavigate } from 'react-router-dom';
import language from '../../variables/language';

export default function Selector(props) {
   const navigate = useNavigate();
   const [data, setData] = useState([]);
   const [input,setInput] = useState({'name' : props.name, 'value' :  0});
   const [satatus, setSatatus] = useState('wait');
   const [info,setInfo] = useState('...');
   const [count, setCount] = useState(1);
   const [disabled, setDisabled] = useState(() => {
            if (props.selectors && props.selectors[props.name] && props.selectors[props.name].disabled !== undefined) {
              return true;
            }
            return false;
          });

   useEffect(() => {
    const interval = setInterval(() => {
      // Tworzenie napisu z kropkami na podstawie wartości count
      const newDots = Array(count).fill('.').join('');
      setInfo(newDots);

      // Inkrementacja count
      setCount(prevCount => (prevCount >= 3 ? 1 : prevCount + 1));
    }, 500);

    // Czyszczenie interwału po 5 sekundach
    const timeout = setTimeout(() => {
      clearInterval(interval);
      setInfo('...')
    }, 2000);
    setInput({'name' : props.name, 'value' :  props.value}); 
    const updatedInputs = { ...input };
    updatedInputs.value = input.value;
    updatedInputs.name = input.name;
    setInput(updatedInputs);
    props.selectorsHundler(updatedInputs);
    // if(input.name == 'conversationId'){
    //   console.log("converstion select -> "+ input.name + " | "+ input.value);
    // }

    // Zatrzymywanie interwału i czyszczenie timeoutu po odmontowaniu komponentu
    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
    
  }, []);


   useEffect(() => {
    if(satatus === 'none'){
      setInfo('...');
     
    }else if(satatus === 'wait'){
     
      
    }else if(satatus === 'done'){
   
    }else if(satatus === 'error'){
      
    }else if(satatus === 'forbidden'){
    
    }else if(satatus === 'timeout'){
      
    }
  }, [satatus]);


   const handleInputChange = (value, fieldName) => {
    //console.log("handleInputChange-------------value is =: "+value);
      const updatedInputs = { ...input };
      updatedInputs.value = value;
      updatedInputs.name = fieldName;
      setInput(updatedInputs);
      props.selectorsHundler(updatedInputs);
      //console.log("handleInputChange----------"+JSON.stringify(updatedInputs));
  };

  

   useEffect(() => {
   // console.log('in select :->> props.value=' + props.value);
    setSatatus('wait');
     axios.get(props.url, { withCredentials: true })
       .then(response => {
        if(props.name==='responsibleId' || props.name==='teacher'  ){
          const updatedInputs = { ...response.data };
          Object.keys(updatedInputs).forEach((name)=>{
              updatedInputs[name]['name'] = updatedInputs[name]['firstName']+" "+ updatedInputs[name]['lastName'];
          });
        }
        if(props.name==='nextId' || props.name==='fromId'){
          const updatedInputs = { ...response.data };
          Object.keys(updatedInputs).forEach((name)=>{
              updatedInputs[name]['name'] = updatedInputs[name]['text'];
          });
        }
     
         setData(response.data);
         setSatatus('none');
        // console.log("in select response------ value:" + props.value);
         if (response.data && response.data.length === 1) {
           response.data.forEach(item => {
              if (item.id && input.name) {
                  handleInputChange(item.id, input.name);
              } else {
                  console.error('Brak wymaganych danych (item.id lub input.name)');
              }
          });
          }
          if (props.value && typeof props.value !== 'undefined') {
            //  console.log("in select================================ value:" + props.value);
              handleInputChange(props.value, input.name);
          }
       })
       .catch(err => {
         if(err.response.status === 401 ){
          navigate('/login');
         }
         setSatatus('error');
         console.log(err);
       });
   }, [ props.url, navigate,props.value]);
  return (
    <select key={input.name+'select'} name={input.name}   value={input.value} disabled={disabled}
    onChange={ (e) => handleInputChange( e.target.value, input.name ) } className={props.className} >
      {/* {data.map(item => (
         <option className='from-option txt-c' key={item.id}
              value={item['id']}>{ item['name'] }</option>
       ))} */}
       <option className='from-option txt-c' >{info}</option>
        {Array.isArray(data) ? (
          data.map(item => (
            <option className='from-option txt-c' key={item.name+item.id}
                 value={item['id']}>{ item['name'] }</option>
          ))
        ) : (
          <option>Empty list</option>
        )}
    </select>
  )
}
