import axios from 'axios';
import React from 'react'
import {  useNavigate } from 'react-router-dom';

export default function Icon(props) {
   
   const navigate = useNavigate();
   const onClickHundler=()=>{
    //  console.log("onclok hundler icons  "+props.mode);
      if(props.mode==='delete'){
         
         if(window.confirm("Czy na pewno chcesz usunąć ten element?")){
            deleteProcess();
         }
         
      }else if(props.mode==='edit' ){
         editProcess();
      }else if(props.mode==='menu' ){
         menuProcess();
      }else if( props.mode==='nextedit'){
         nextEditProcess('nextedit');
      }else if( props.mode==='fromedit'){
         nextEditProcess('fromedit');
      }else if(props.mode==='card'){
         cardProcess();
      }else if(props.mode==='cardevent'){
         cardEventProcess();
      }else if(props.mode==='download'){
         downloadProcess();
      }else if(props.mode==='copy'){
         copyProcess();
      }else if(props.mode==='copy-url'){
         copyUrlProcess();
      }else if(props.mode==='copy-iframe'){
         copyIframeProcess();
      }
   
     
   }
   const deleteProcess =()=>{
    //  console.log("delete deleteProcess000000000000000000 ");
      axios.delete(props.url, {
         withCredentials: true,
         headers: {
             'Content-Type': 'application/json',
         },
     }
      ).then(response => {
         props.onRefreshHundler();
      })
      .catch(err => {
         window.alert("Елемент не видалено скоріш за все він пов'язаний з іншими елементами, тож спочатку переконайтеся чи жоден елемент більше не повязаний з цим !");
         console.log('Błąd podczas wysyłania żądania:', err.message);
      });
   }
   const editProcess =()=>{
      props.stateHundler('edit',props.id);
   }
   const menuProcess =()=>{
      if(props.item && props.item.lesson){
         props.stateHundler('menu',props.item.lesson.id);
      }
      
   }
   const nextEditProcess =(key)=>{
      props.stateHundler(key,props.id);
   }
   const cardProcess =()=>{
      navigate(props.url );
   }
   const cardEventProcess =()=>{
      navigate(props.clearurl+'/' + props.item.student.id );
   }
   const copyProcess =()=>{

      var text = 'https://t.me/Eng_for_ukr_bot?start='+ props.item['hashCode'] ;

      handleCopy(text);
   }
   const handleCopy = async (vartext) => {
      try {
      //  await navigator.clipboard.writeText(vartext);
        fallbackCopyToClipboard(vartext)
        //setMessage("Tekst został skopiowany do schowka!");
      } catch (err) {
       // setMessage("Nie udało się skopiować tekstu.");
      }
    };
    const fallbackCopyToClipboard = (text) => {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      textArea.style.position = "fixed"; // Unikaj przewijania strony
      textArea.style.opacity = "0";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
    
      try {
        const successful = document.execCommand("copy");
        if (successful) {
         alert("Copied : "+text);
          console.log("Fallback: Text copied to clipboard!");
        } else {
          console.error("Fallback: Unable to copy text.");
        }
      } catch (err) {
        console.error("Fallback: Error copying text:", err);
      } finally {
        document.body.removeChild(textArea);
      }
    };
   const copyUrlProcess =()=>{

     
      var text = props.clearurl + props.item[props.headers.urlfield] ;

      handleCopy(text);
   }
   const copyIframeProcess =()=>{

      var text =  '<iframe src=" ' +props.clearurl+props.item['id']+ ' "  width="800"  height="600" frameborder="0" allowfullscreen  loading="lazy" title="Description of the content"></iframe>';

      handleCopy(text);
   }

   const message =()=>{
        
   }
   const downloadProcess =()=>{
     // console.log(" download url: "+props.item.path);
      var filePath = ''+props.item.path;
      window.location.href = filePath;
     //const newWindow = window.open(filePath);

      // newWindow.onload = function() {
      //     newWindow.document.body.innerHTML = '<object data="' + filePath + '" type="application/pdf" width="100%" height="100%"></object>';
      // };
     // window.open('localhost:88'+props.item.path);
     // navigate(props.item.path);
   }
  return (
    <span className={props.className} data-url={props.url} onClick={onClickHundler}></span>
  )
}
