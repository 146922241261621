import React, { useEffect, useState } from 'react';
import FormUpdate from '../shared/FormUpdate';
import Form from '../../components/forms/Form';
import ActivityList from '../../components/settings/ActivityList';

export default function FormInterface(props) {
   const [inputs, setInputs] = useState(props.inputs);
   const [selectors, setSelectors] = useState(props.selectors);
   const [mode, setMode] = useState('edit');
  
  

   useEffect(()=>{
      const updatedInputs = { ...inputs }
      Object.keys(inputs).forEach(fieldName => {
         if(typeof props.data[fieldName] === 'object' && props.data[fieldName] ){
            if(props.data[fieldName].id){
               updatedInputs[fieldName].value = props.data[fieldName].id+'';
            }else{
               updatedInputs[fieldName].value = '';
            }
             
         }else{
            updatedInputs[fieldName].value = props.data[fieldName]+'';
         }
         
       });
       setInputs(updatedInputs);
   },[props.data,props.onRefreshHundler]);



  return (
   <div>
      
      <Form inputs={inputs}  url={props.url} mode={mode}  name={props.name} onRefreshHundler={props.onRefreshHundler} ></Form>
   </div>

  )
}
