import { useEffect, useState } from 'react';
import Table from '../tables/Table';
import axios from 'axios';
import {  useNavigate } from 'react-router-dom';

export default function ActivityList(props) {
   const navigate = useNavigate();
  const [headers, setHeaders] = useState(props.headers);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (props.headers) {
      setHeaders(props.headers);
    }
    axios.get(props.url, { withCredentials: true })
      .then(response => {
        setData(response.data);
      })
      .catch(err => {
        if(err.response.status === 401 ){
          console.log(err);
          navigate('/login');
        }else{
          console.log(err);
        }
        
      });
  }, [props.headers, props.url, navigate,props.done]);
  return (
    <div>
      <h3>{props.name}</h3>
      <Table headers={headers} 
      label={props.label}  
      onRefreshHundler={props.onRefreshHundler} 
      stateHundler={props.stateHundler} 
      table='table' 
      thead='table-header' htr='table-h-tr' 
      th='table-h-th ' tbody='table-body' btr='table-b-tr' td='table-b-td ' data={data} />
    </div>
  )
}
