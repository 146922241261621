import axios from 'axios';
import Layout from '../shared/Layout';
import { useContext, useEffect, useState } from 'react';
import DetailsContext from '../../providers/DetailsContext';
import language from '../../variables/language';
import { useLocation, Link, useParams  } from 'react-router-dom';
import FlexList from '../../components/settings/FlexList';
import FoldedHeader from './FoldedHeader';
import Form from '../../components/forms/Form';
const StudentsConversation = (props) => {
  // const [params, setParams] = useState({});
  // const API_URL = process.env.REACT_APP_API_URL;
  // const moduleName = 'firmy';
  const label = 'conversationprogress';
  const { id } = useParams();
  const [paidId ,setPaidId] = useState('0');
  var params = '?student='+id;
  var paramsPaid = '&isPaid='+paidId;
  const url = '/api/conversationprogress';
  
  const progressUrl = "/api/conversationprogress";
  const byConverstionParams = "?student="+props.id;
  var byPublic = "&isForMe="+paidId;

 // const { setTheme } = useContext(DetailsContext);
 const location = useLocation();
 const [modalState ,setModalState] = useState('none');
 const [refresh, setRefresh] = useState(0);
 const [selectorRefresh, setSelectorRefresh] = useState(0);
 const [toUpdateId , setToUpdateId] = useState('');
const [showForm,setShowForm] = useState('1');
 const [key, setKey] = useState(0);

 const reloadComponent = () => {
   setKey(prevKey => prevKey + 1); // Zmień klucz, aby wymusić przeładowanie
 };



   const [mixedinputs, setMixednputs] = useState(
    {
     'student':{'name':'student','type' : 'number','tagtype' : 'select','url':'/api/students','value' : id,'disabled':true,'width':'49%'},
     'conversationId':{'name':'conversationId','type' : 'number','tagtype' : 'select','url':'/api/conversations?isPublic=0','value' : id,'disabled':false,'width':'49%'},
    });
    const [inputsAlgorithm, setInputsAlgorithm] = useState(
      {
       'student':{'name':'student','type' : 'number','tagtype' : 'select','url':'/api/students','value' : id,'disabled':true,'width':'49%'},
       'algotithm':{'name':'algotithm','type' : 'number','tagtype' : 'select','url':'/api/algorithms','value' : '','disabled':false,'width':'49%'},
      });

    
    const [headersProgressConv, setHeadersProgressConv] = useState(
      {
        'isFinished':{},
        'conversationId':{},
        'icons':{
          'icon-delete':{'name':'icon-delete','type' : 'icon','url':progressUrl,'value' : '','mode':'delete'},
         'icon-messages':{'name':'icon-messages','type' : 'icon','url':'/messages/byconvprogress','value' : '','mode':'card'},
         }
       }
      );

 

   const stateHundler = (state,id) => {
     if(state === 'edit'){
       setModalState('edit');
       console.log("!!! stateHundler edit ->"+id);
       setToUpdateId(id);
     }else if(state === 'none'){
       setModalState('none');
     }
   }
   const onRefreshHundler = ()=> {
     if(refresh==="1"){
       setRefresh('0');
     }else{
       setRefresh('1');
     }
     reloadComponent();
     
   }
   useEffect(()=>{
    onRefreshHundler();
  //  params = params +'&isPaid='+paidId;
   },[toUpdateId])

   const formHundler = (id) => {

    console.log("changed id "+ id);
    setShowForm(id);
    reloadComponent();
   }


return (

  <div  style={{width:'95%',marginInline:'auto'}}>
   <FoldedHeader paidId={paidId} setPaidId={setPaidId} name="Lessons"  opened='300px' idHundler={formHundler} >
      {
      showForm === '1' ? (<Form key={key} inputs={mixedinputs}  url={url} 
        onRefreshHundler={onRefreshHundler}  stateHundler={stateHundler} 
        name={language[language.lang].modals[label].createTitle}></Form>) 
        : (<Form key={key} inputs={inputsAlgorithm}  url={'/api/conversationprogress/byalgorithm'} 
          onRefreshHundler={onRefreshHundler}  stateHundler={stateHundler} 
          name={language[language.lang].modals[label].createTitle}></Form>)
    }
     
     
   </FoldedHeader>

  
     <div className='main-content txt-c scrooll' style={{maxHeight: '70vh',overflowX: 'auto'}}>
     <FlexList name='' table='table' 
               thead='table-header' htr='table-h-tr' 
               th='table-h-th-bot-con ' tbody='table-body' 
               btr='table-b-tr' td='table-b-td' 
               url={progressUrl+byConverstionParams+byPublic} done={refresh} stateHundler={stateHundler} onRefreshHundler={onRefreshHundler} headers={headersProgressConv}/>
               
     </div>  
  

   </div>
)
}

export default StudentsConversation