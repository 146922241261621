import  {useState ,useEffect} from 'react'
import language from '../../variables/language';
import '../../css/form.css'

import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Selector from '../../components/forms/Selector';
import Feedback from '../../pages/shared/Feedback';

export default function Form(props) {
   const [errMsg, setErrMsg] = useState('');
   const [visibility, setVisibility] = useState('flex');
   const [status, setStatus] = useState('none');
   const [inputs,setInputs] = useState(props.inputs);
   const navigate = useNavigate();
 
   
  
   const handleInputChange = (value, fieldName) => {
      
      const updatedInputs = { ...inputs };
    //  console.log("updateINputs----------"+"["+fieldName+"]"+JSON.stringify(updatedInputs));
      if(fieldName && value && value !== 'undefined' && value !== 'null'){

         updatedInputs[fieldName].value =  value;
      }else{
         updatedInputs[fieldName].value =  '';
      }
      
      
      setInputs(updatedInputs);
    //  console.log("Finish1----------"+JSON.stringify(inputs));
  };
 

   const submitHundler =(e)=>{
      e.preventDefault();
    //  console.log("Inputs --->"+JSON.stringify(inputs));
      const formData = {};
         // Zbieranie wartości z pól formularza
        // const inputsTosave = document.querySelectorAll('.submit-field');
        Object.keys(inputs).forEach(fieldName => {
         
           formData[fieldName] = inputs[fieldName].value !=='null' || inputs[fieldName].value !=='undefined' ? inputs[fieldName].value : '';
         //  console.log(inputs[fieldName].name+ '<- is ->' + formData[fieldName]);
         });
        // console.log('end hundler -> size of inputs: '+Object.keys(inputs).length);
      
       //  console.log(" formdata--->"+ JSON.stringify(formData));
         if(props.mode==='edit'){
            axios.put(props.url,
               JSON.stringify(formData), {
                   withCredentials: true,
                   headers: {
                       'Content-Type': 'application/json',
                   },
               }
           ).then(response => {
              statusHundler('done');
           })
           .catch(err => {
             if (err.response) {
               
                if(err.response.status===403){
                   statusHundler('forbidden');
                }else if(err.response.status=== 500){
                   statusHundler('error');
                } else{
                  statusHundler('error');
                }
            } else {
                console.log('Błąd podczas wysyłania żądania:', err.message);
            }
           });
         }else{
            axios.post(props.url,
               JSON.stringify(formData), {
                   withCredentials: true,
                   headers: {
                       'Content-Type': 'application/json',
                   },
               }
           ).then(response => {
              statusHundler('done');
           })
           .catch(err => {
             if (err.response) {
               
                if(err.response.status===403){
                   statusHundler('forbidden');
                }else if(err.response.status=== 500){
                   statusHundler('error');
                } else{
                  statusHundler('error');
                }
            } else {
                console.log('Błąd podczas wysyłania żądania:', err.message);
            }
           });
         }
         
        setStatus('wait');
        
         
   }
   const statusHundler=(status)=>{
      setStatus(status);
   }
   //status of form updater 
    useEffect(() => {
      if(status === 'none'){
         setVisibility('flex');
      }else if(status === 'wait'){
         setVisibility('none');
      }else if(status === 'done'){
         setVisibility('none');
         props.onRefreshHundler();
      }else if(status === 'error'){
         setVisibility('none');
      }else if(status === 'forbidden'){
         setVisibility('none');
      }else if(status === 'timeout'){
         setVisibility('none');
      }
    }, [status]);

    
    useEffect(() => {
    
     setInputs(props.inputs);
      //console.log("Refreszed form -> id=["+props.refresh+"]"+ JSON.stringify(inputs));
   }, [props.onRefreshHundler,props.refresh,props.inputs]);

   


  return (
    <div style={{width:'100%'}}>
      <form className='form-container txt-c' style={{display:visibility}} >
            
            {Object.keys(inputs).map(field => (
              props.inputs[field].tagtype &&  props.inputs[field].tagtype === 'input' ?
               <div key={props.inputs[field].name} step={props.inputs[field].step ? props.inputs[field].step : '1'}  style={{width:props.inputs[field].width}}>
                  <div className='flex-from-input-wraper' data-label={language[language.lang][props.inputs[field].name]} >
                     <input className='submit-field from-input txt-c'  required={true}
                     placeholder={language[language.lang][props.inputs[field].name]} 
                     key={props.inputs[field].name + 'inp'}
                     type={props.inputs[field].type}
                     name={props.inputs[field].name}
                     disabled={props.inputs[field].disabled}
                  
                     value={ inputs[field].value==='undefined'  || inputs[field].value==='null'? '' :  inputs[field].value}
                     onChange={(e) => handleInputChange(e.target.value, inputs[field].name)}
                     data-label={language[language.lang][props.inputs[field].name]} />
                  </div>
               </div>

               :
               props.inputs[field].tagtype && props.inputs[field].tagtype === 'select' ?
               <div key={props.inputs[field].name}  style={{width:props.inputs[field].width}}>
                  <div className='flex-from-input-wraper'  data-label={language[language.lang][props.inputs[field].name]}>
                  <Selector className='submit-field form-selection txt-c' 
                  key={'sel-'+props.inputs[field].name} 
                  selectorsHundler={handleInputChange} 
                  disabled={props.inputs[field].disabled}
                  value={inputs[field].value==='undefined' || inputs[field].value==='null' ? '' :  inputs[field].value} 
                  name={props.inputs[field].name} 
                  url={props.inputs[field].url} /> 
                  </div>
               </div>
               :
               props.inputs[field].tagtype && props.inputs[field].tagtype === 'textarea' ?
               <div key={props.inputs[field].name} style={{width:props.inputs[field].width}}>
                  <div className='flex-from-input-wraper' data-label={language[language.lang][props.inputs[field].name]} >
                  <textarea className='submit-field from-input txt-c'  required={true}
                  placeholder={language[language.lang][props.inputs[field].name]} 
                  key={props.inputs[field].name + 'inp'}
                  type={props.inputs[field].type}
                  name={props.inputs[field].name}
                  disabled={props.inputs[field].disabled}
                  value={inputs[field].value==='undefined' || inputs[field].value==='null' ? '' :  inputs[field].value}
                  onChange={(e) => handleInputChange(e.target.value, inputs[field].name)}
                  data-label={language[language.lang][props.inputs[field].name]} ></textarea>
                  </div>
               </div>
            :""
            ))}
       
          
      
            {/* submit button */}
            <button type='submit' className=' form-button txt-c' onClick={submitHundler}>
               {language[language.lang].save}
            </button>
     
      </form>
      <Feedback statusHundler={statusHundler} status={status} />
      
    </div>
  )
}
