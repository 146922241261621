import React from 'react';
import '../../css/table.css';
import language from '../../variables/language';
import Icon from './Icon';
 
 const Tr = ({ headers, item ,btr, td , onRefreshHundler, stateHundler,label}) => {
  
  var styles = {};
  var nextId = 0;
  var fromId = 0;

   return (
     <tr  className={btr} key={item.id}>
       {Object.keys(headers).map(header => {
       // console.log(item.id);
       if(headers['icons'] && headers['icons']['icon-edit']){
        if(headers['icons']['icon-edit'].mode === 'nextedit' && item['nextId']){
          //  console.log(JSON.stringify("is next id exist"+item['nextId']?'true'+ item['nextId']['id']:'false'));
            nextId = item['nextId']['id'];
         }
         if(headers['icons']['icon-edit'].mode === 'fromedit' && item['fromId']){
          // console.log(JSON.stringify("is fromId id exist"+item['fromId']?'true'+ item['fromId']['id']:'false'));
           fromId = item['fromId']['id'];
        }
      }
       let concatenatedNames = '';
     
       if(header === 'meta'){
        if(item?.meta){
          concatenatedNames += item.meta.split("-")[0];
        }else{
          concatenatedNames = 'empty';
        }
       }
       if(header === 'isBot'){
      
        if(item['isBot'] === 1){
       

          if(item?.outerUser?.name){
            concatenatedNames += "item['outerUser']['name']";
          }else{
            if(item.conversationProgressId?.meta){
              concatenatedNames += item.conversationProgressId.meta.split("-")[0];
            }else{
              concatenatedNames = 'empty';
            }
           
            
          }
          
          
        }else{
          concatenatedNames = 'Bot';
          styles = {filter: 'hue-rotate(158deg)',scale:"0.97",borderBottom:'0px solid gray'};
        }
       }
       if(header === 'isPaid' || header === 'isFinished'){
        if(item['isPaid'] === 1 || item['isFinished'] === 1){
          concatenatedNames = 'Yes';
          styles = {background: 'rgb(108 255 159 / 40%)',borderBottom:'0px solid gray'};
        }else{
          concatenatedNames = 'No';
        }
       }
       if(item['hasDone']){
        if(item['hasDone'] === 1){
         // concatenatedNames = 'Yes';
          styles = {background: 'red',borderBottom:'0px solid gray'};
        }else{
          styles = {background: 'blue',borderBottom:'0px solid gray'};
         // concatenatedNames = 'No';
        }
       }
       if(header === 'end' || header === 'start' ){
        concatenatedNames = item[header];
        concatenatedNames = concatenatedNames.replace('T',' at ');
        
       }
     
        if( headers[header].name && headers[header].name !== 'boolean'  ){
     
          {Object.keys(headers[header]).map(name => {
           
            if(item[header]){
             // console.log(JSON.stringify(item));
             
            
              if(typeof headers[header][name] === 'object'){
      
                Object.keys(headers[header][name]).forEach(it => {
             
                  if( item[header][headers[header][name][it]]){
                    concatenatedNames += ' | ' + item[header][headers[header][name][it]][it];
                  }
                 
                
                  
                });
              }else{
                concatenatedNames =concatenatedNames +' '+ item[header][headers[header][name]];
              }
            }
            
          })}
        }
        if(header && headers[header].name === 'boolean'){
          concatenatedNames  = '...';
           if(item[header] === 1){
            concatenatedNames  = 'Yes';
           }
           if(item[header] === 2){
            concatenatedNames = 'No';
           }
          
          }
        return(
         <td className={td} key={header} style={styles}>
               <div className='txt-c-secon table-buttons-container' data-label={language[language.lang][header]} >
                { 
                
                 (item[header] || concatenatedNames ==='...' )  && ( typeof item[header] === 'object' ||  header === 'isBot' || header ==='isPaid' || header === 'end' || header === 'start'  || header === 'isFinished' || header === 'meta' || headers[header].name  === 'boolean'   )? 
                (concatenatedNames ? concatenatedNames : item[header].name) 
                      : (item[header] !== 'undefined' ?  item[header]      :'')

                      
                }
                {  headers.icons === headers[header]? Object.keys(headers[header]).map(icon => (
                  <Icon label={label} key={headers[header][icon].name+'-'+item.id}  item={item} headers={headers[header][icon]}
                  mode={headers[header][icon].mode} stateHundler={stateHundler}  clearurl={headers[header][icon].url}
                  className={headers[header][icon].name} url={headers[header][icon].url +'/'+ item['id']} 
                  id={ headers?.[header]?.[icon]?.mode === 'nextedit' ? ( item?.nextId?.id ? nextId : item.id ) :  (headers?.[header]?.[icon]?.mode === 'fromedit' && item?.fromId?.id ? fromId : item.id )} data-label=''
                   onRefreshHundler={onRefreshHundler} /> )) 
                  : ''}
                  
                </div>
               

         </td>
        );
         
    })}
       
     </tr>
   );
 };
 export default Tr;