import  { useState ,useEffect} from 'react'
import Panels from '../../components/func panel/Panels'
import Search from '../../components/func panel/Search'

export default function ConvHeader( {children,name,opened,done,setPaidId,paidId}) {
   const [heigth, setHeight] = useState('fit-content');
   const [heigthMax, setHeightMax] = useState(0);
   const [myPaidId, setMyPaidId] = useState(paidId);
   const folder = {
      height:heigth,
      maxHeight:heigthMax,
      overflow:'hidden',
   }
   const heightHundler = () =>{
      if(heigthMax===0){
         setHeightMax('fit-content');
      }else{
         setHeightMax(0);
      }
   }
   const paidFiltr = () =>{
      if(myPaidId==='0'){
         setPaidId('1');
      }else{
         setPaidId('0');
      }
   }
    useEffect(() => {
      setMyPaidId(paidId)
    }, [paidId]);
   //  useEffect(() => {
   //    setHeightMax(done)
   //  }, [done]);
  return (
   <header  className='inner-header-block' style={{width:'95%',marginInline:'auto'}}>
      <div className='header-panel txt-c'>
            <div className='header-txt ' >{name}</div>
            <Panels>
              
            </Panels>
            <div className='func-container'>
              <button className='func-button' onClick={paidFiltr}>
               {myPaidId === '0' ?
                (<svg xmlns="http://www.w3.org/2000/svg" className='search-icon' height="24px" viewBox="0 0 24 24" width="24px" fill="#e8eaed"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M20 6h-8l-2-2H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm0 12H4V6h5.17l2 2H20v10zm-5-5c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm-4 4h8v-1c0-1.33-2.67-2-4-2s-4 .67-4 2v1z"/></svg>) 
               :(<svg xmlns="http://www.w3.org/2000/svg" className='search-icon' height="24px" viewBox="0 0 24 24" width="24px" fill="#e8eaed"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M20 6h-8l-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm0 12H4V6h5.17l2 2H20v10zm-6.92-3.96L12.39 17 15 15.47 17.61 17l-.69-2.96 2.3-1.99-3.03-.26L15 9l-1.19 2.79-3.03.26z"/></svg>) }
               
              </button>
            </div>
            <div className='func-container'>
              <button className='func-button' onClick={heightHundler}>
                  <svg xmlns="http://www.w3.org/2000/svg" className='search-icon' viewBox="0 -960 960 960" ><path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"/></svg>
              </button>
            </div>
      </div>
      <div className='folder-container' style={folder}>
          {children}
      </div>
   
   </header>
  )
}
