import  { useState ,useEffect} from 'react'
import Panels from './Panels'
import FunctionSearch from './FunctionSearch'

export default function FunctionslHeader( {children,name,height}) {
   
   const [heigthMax, setHeightMax] = useState(0);
 
    useEffect(() => {
      setHeightMax(height)
    }, [height]);
  return (
   <header  className='header-block' style={{position:'relative',height:heigthMax}}>
      <div className='header-panel txt-c'>
            <div className='header-txt ' style={{marginTop:4}}>{name}</div>
            <Panels>
               <FunctionSearch>{children}</FunctionSearch>
               
            </Panels>
            <div className='func-container'>
            
            </div>
      </div>
      
   
   </header>
  )
}
