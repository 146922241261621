import { useEffect, useState } from 'react';
import axios from 'axios';
import {  useNavigate } from 'react-router-dom';
import language from '../../variables/language';

export default function Selector(props) {
   const navigate = useNavigate();
   const [data, setData] = useState([]);
   const [input,setInput] = useState({'name' : props.name, 'value' :  0});
   const [satatus, setSatatus] = useState('wait');
   const [info,setInfo] = useState('...');
   const [count, setCount] = useState(1);
   const [disabled, setDisabled] = useState(props.disabled);

   useEffect(() => {
    const interval = setInterval(() => {
      // Tworzenie napisu z kropkami na podstawie wartości count
      const newDots = Array(count).fill('.').join('');
      setInfo(newDots);

      // Inkrementacja count
      setCount(prevCount => (prevCount >= 3 ? 1 : prevCount + 1));
    }, 500);

    // Czyszczenie interwału po 5 sekundach
    const timeout = setTimeout(() => {
      clearInterval(interval);
      setInfo('...')
    }, 2000);
    setInput({'name' : props.name, 'value' :  props.value}); 
    
    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
    
  }, [ props.value]);


   useEffect(() => {
    if(satatus === 'none'){
      setInfo('...');
     
    }else if(satatus === 'wait'){
     
      
    }else if(satatus === 'done'){
   
    }else if(satatus === 'error'){
      
    }else if(satatus === 'forbidden'){
    
    }else if(satatus === 'timeout'){
      
    }
  }, [satatus]);


   const handleInputChange = (value, fieldName) => {
      const updatedInputs = { ...input };
      updatedInputs.value = value;
      updatedInputs.name = fieldName;
      setInput(updatedInputs);
      props.selectorsHundler(value,fieldName);
  };

  

   useEffect(() => {
    setSatatus('wait');
     axios.get(props.url, { withCredentials: true })
       .then(response => {
        if(props.name==='responsibleId' || props.name==='teacher' ){
          const updatedInputs = { ...response.data };
          Object.keys(updatedInputs).forEach((name)=>{
              updatedInputs[name]['name'] = updatedInputs[name]['firstName']+" "+ updatedInputs[name]['lastName'];
          });
        }
        if(props.name==='nextId' || props.name==='fromId'){
          const updatedInputs = { ...response.data };
          Object.keys(updatedInputs).forEach((name)=>{
              updatedInputs[name]['name'] = updatedInputs[name]['text'];
          });
        }
        if(props.name==='date' && props){
          const updatedInputs = { ...response.data };
          Object.keys(updatedInputs).forEach((name)=>{
              updatedInputs[name]['name'] = updatedInputs[name]['dayOfWeek'];
              updatedInputs[name]['id'] = updatedInputs[name]['date'];
          });
        }
     
         setData(response.data);
         setSatatus('none');
         if (response.data && response.data.length === 1) {
           response.data.forEach(item => {
              if (item.id && input.name) {
                  handleInputChange(item.id, input.name);
              } else {
                  console.error('Brak wymaganych danych (item.id lub input.name)');
              }
          });
          }
          if (props.value && typeof props.value !== 'undefined') {
              handleInputChange(props.value, input.name);
          }
       })
       .catch(err => {
         if(err.response && err.response.status === 401 ){
          navigate('/login');
         }
         setSatatus('error');
         console.log(err);
       });
   }, [ props.url, navigate]);
  return (
    <select name={input.name} key={input.name}  value={input.value} disabled={disabled}
    onChange={ (e) => handleInputChange( e.target.value, input.name ) } className={props.className} >
    
       <option className='from-option txt-c' >{info}</option>
        {Array.isArray(data) ? (
          data.map(item => (
            <option className='from-option txt-c' key={item['id']+'option'}
                 value={item['id']}>{ item['name'] }</option>
          ))
        ) : (
          <option>Empty list</option>
        )}
    </select>
  )
}
