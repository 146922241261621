import React, { useEffect, useState } from 'react';
import FormUpdate from './FormUpdate';
import Form from './Form';

export default function ModalWindow(props) {
   const [inputs, setInputs] = useState(props.inputs);
   const [selectors, setSelectors] = useState(props.selectors);
   const [mode, setMode] = useState('edit');

   const stopProp = (event) => {
      event.stopPropagation();
   }

   useEffect(()=>{
      const updatedInputs = { ...inputs }
      Object.keys(inputs).forEach(fieldName => {
         updatedInputs[fieldName].value = props.data[fieldName]+'';
       });
       const updatedSelectors = { ...selectors }
       Object.keys(selectors).forEach(fieldName => {
         if (updatedSelectors.hasOwnProperty(fieldName)) {
            // Jeśli pole już istnieje, aktualizujemy jego wartość
           // console.log("selectors> name: "+fieldName+' | data: '+JSON.stringify(props.data[fieldName], null, 2));
             var id = props.data[fieldName] && typeof props.data[fieldName] === 'object' && props.data[fieldName]['id'] ? props.data[fieldName]['id'] :props.data[fieldName];
            updatedSelectors[fieldName].value = id;
        } else {
            var id = props.data[fieldName] && typeof props.data[fieldName] === 'object' && props.data[fieldName]['id'] ? props.data[fieldName]['id'] : props.data[fieldName];
            // Jeśli pole nie istnieje, dodajemy nowe pole do obiektu inputs
            updatedSelectors[fieldName] = {
                'value': id
            };
         }
         
       });
       setSelectors(updatedSelectors);
       setInputs(updatedInputs);
   },[props.data]);



  return (
   <div className='modal-window' onClick={stopProp}>
      <h3 className='modal-w-title txt-c' >{props.title}</h3>
      <Form inputs={inputs} selectors={selectors} url={props.url} mode={mode}  name={props.name} onRefreshHundler={props.onRefreshHundler} ></Form>
   </div>
  )
}
