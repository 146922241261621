import  {useState ,useEffect} from 'react'
import language from '../../variables/language';
import '../../css/form.css'

import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Selector from './Selector';
import Feedback from '../../pages/shared/Feedback';

export default function SearchForm(props) {
   const [errMsg, setErrMsg] = useState('');
   const [visibility, setVisibility] = useState('flex');
   const [status, setStatus] = useState('none');
   const [inputs,setInputs] = useState(props.inputs);
   const navigate = useNavigate();
 
   
  
   const handleInputChange = (value, fieldName) => {
      
      const updatedInputs = { ...inputs };
      if(fieldName && value && value !== 'undefined' && value !== 'null'){
         updatedInputs[fieldName].value =  value;
      }else{
         updatedInputs[fieldName].value =  '';
      }
      
      setInputs(updatedInputs);
   
  };
 

   const submitHundler =(e)=>{
      
      e.preventDefault();
      let params = '';
      const formData = {};
        Object.keys(inputs).forEach(fieldName => {
         
           formData[fieldName] = inputs[fieldName].value !=='null' ? inputs[fieldName].value : '';
           if(inputs[fieldName].value && inputs[fieldName].value !=='null' && inputs[fieldName].value !=='undefined' && inputs[fieldName].value !=='' && inputs[fieldName].value !=='...'){
            params += fieldName + '=' + inputs[fieldName].value + '&';
            console.log("value-"+inputs[fieldName].value);
            
           }
           
         });

         
         props.setSearchParameters(params);
         props.onRefreshHundler();
        
       
   }
   const statusHundler=(status)=>{
      setStatus(status);
   }
   //status of form updater 
    useEffect(() => {
      if(status === 'none'){
         setVisibility('flex');
      }else if(status === 'wait'){
         setVisibility('none');
      }else if(status === 'done'){
         setVisibility('none');
         props.onRefreshHundler();
      }else if(status === 'error'){
         setVisibility('none');
      }else if(status === 'forbidden'){
         setVisibility('none');
      }else if(status === 'timeout'){
         setVisibility('none');
      }
    }, [status]);

    
    useEffect(() => {
    
     setInputs(props.inputs);
      //console.log("Refreszed form -> id=["+props.refresh+"]"+ JSON.stringify(inputs));
   }, [props.onRefreshHundler,props.refresh,props.inputs]);

   


  return (
    <div style={{width:'100%'}}>
      <form className='form-container txt-c' style={{display:visibility}}>
         {/* <h3 className='header-name'>{props.name}</h3> */}

            {/* inputs */}
            
            {Object.keys(inputs).map(field => (
               props.inputs[field].tagtype === 'input' ?
               <div key={props.inputs[field].name} step={props.inputs[field].step ? props.inputs[field].step : '1'}  style={{width:props.inputs[field].width}}>
                  <div className='flex-from-input-wraper' data-label={language[language.lang][props.inputs[field].name]} >
                     <input className='submit-field from-input txt-c'  required={true}
                     placeholder={language[language.lang][props.inputs[field].name]} 
                     key={props.inputs[field].name + 'inp'}
                     type={props.inputs[field].type}
                     name={props.inputs[field].name}
                     disabled={props.inputs[field].disabled}
                  
                     value={ inputs[field].value==='undefined'? '' :  inputs[field].value}
                     onChange={(e) => handleInputChange(e.target.value, inputs[field].name)}
                     data-label={language[language.lang][props.inputs[field].name]} />
                  </div>
               </div>

               :
               props.inputs[field].tagtype === 'select' ?
               <div key={props.inputs[field].name}  style={{width:props.inputs[field].width}}>
                  <div className='flex-from-input-wraper'  data-label={language[language.lang][props.inputs[field].name]}>
                  <Selector className='submit-field form-selection txt-c' 
                  
                  selectorsHundler={handleInputChange} 
                  disabled={props.inputs[field].disabled}
                  value={inputs[field].value==='undefined'? '' :  inputs[field].value} 
                  name={props.inputs[field].name} 
                  url={props.inputs[field].url} /> 
                  </div>
               </div>
               :
               props.inputs[field].tagtype === 'textarea' ?
               <div key={props.inputs[field].name} style={{width:props.inputs[field].width}}>
                  <div className='flex-from-input-wraper' data-label={language[language.lang][props.inputs[field].name]} >
                  <textarea className='submit-field from-input txt-c'  required={true}
                  placeholder={language[language.lang][props.inputs[field].name]} 
                  key={props.inputs[field].name + 'inp'}
                  type={props.inputs[field].type}
                  name={props.inputs[field].name}
                  disabled={props.inputs[field].disabled}
                  value={inputs[field].value==='undefined' || inputs[field].value==='null' ? '' :  inputs[field].value}
                  onChange={(e) => handleInputChange(e.target.value, inputs[field].name)}
                  data-label={language[language.lang][props.inputs[field].name]} ></textarea>
                  </div>
               </div>
            :""
            ))}
       
          
      
            {/* submit button */}
            <button type='submit' className=' form-button txt-c' onClick={submitHundler}>
               {language[language.lang].find}
            </button>
     
      </form>
      {/* <Feedback statusHundler={statusHundler} status={status} /> */}
      
    </div>
  )
}
