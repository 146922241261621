import axios from 'axios';
import Layout from '../shared/Layout';
import { useContext, useEffect, useRef, useState } from 'react';
import DetailsContext from '../../providers/DetailsContext';
import language from '../../variables/language';
import { useLocation, Link, useParams  } from 'react-router-dom';
import Header from '../shared/Header';
import Form from '../../components/forms/Form';
import ActivityList from '../../components/settings/ActivityList';
import ModalInterface from './ModalUpdate';
import FlexList from '../../components/settings/FlexList';
import InnerHeader from './InnerHeader';

import ModalUpdate from './ModalUpdate';
import ModalLesson from '../schedule/ModalLesson';
import Function from '../../components/func panel/Function';
import FunctionalHeader from '../../components/func panel/FunctionalHeader';
import IconAdd from '../../components/icons/IconAdd';
import IconFilter from '../../components/icons/IconFilter';
import SearchForm from '../../components/forms/SearchForm';
import {Infopanel}  from '../../components/infopanel/Infopanel';
const Events = () => {
  // const [params, setParams] = useState({});
  // const API_URL = process.env.REACT_APP_API_URL;
  // const moduleName = 'firmy';
  const label = 'events';
  const { id } = useParams();
  const [paidId ,setPaidId] = useState('0');
  var params = '?student='+id;
  
  const [searchParameters , setSearchParameters] = useState('');
  const [searchInput , setSearchInput] = useState('');
  var paramsPaid = '&isPaid='+paidId;
  const url = '/api/events';
  const [urlFinal, setUrlFinal] = useState(url+params+searchParameters) ;
  const urlLessons = '/api/lessons';
 
 // const { setTheme } = useContext(DetailsContext);
 const location = useLocation();
 const [modalState ,setModalState] = useState('none');
 const [refresh, setRefresh] = useState(0);
 const [lisRefresh, setLisRefresh] = useState(0);
 const [refreshContainer, setRefreshContainer] = useState(0);
 const [toUpdateId , setToUpdateId] = useState('');
 const [lessonId , setLessonId] = useState('');

 const [headerContainer , setHeaderContainer] = useState(0);
 const [urlInfopanel ,setUrlInfopanel] = useState(url+'/infopanel'+params);
 const [headerHeight , setHeaderHeight] = useState(0);
 const elementRef = useRef(null);
 const elementRef2 = useRef(null);

 const [key, setKey] = useState(0);

 const reloadComponent = () => {
   setKey(prevKey => prevKey + 1); // Zmień klucz, aby wymusić przeładowanie
 };

 const [headers, setHeaders] = useState(
   {
    'start':{},
    'date':{},
    'isPaid':{},
    'toPay':{'name':'boolean'},
    'attendence':{'name':'boolean'},
    'homework':{},
    'notes':{},
  
   
    'icons':{
      'icon-delete':{'name':'icon-delete','type' : 'icon','url':url,'value' : '','mode':'delete'},
      'icon-edit':{'name':'icon-edit','type' : 'icon','url':url,'value' : '','mode':'edit'},
      'icon-card':{'name':'icon-menu','type' : 'icon','url':url,'value' : '','mode':'menu'},
    }
      
    }
   );

   const [mixedinputs, setMixednputs] = useState(
    {
     'start':{'name':'start', 'type' : 'time','tagtype' : 'input', 'value' : '','disabled':false,'width':'19%'},
     'end':{'name':'end', 'type' : 'time','tagtype' : 'input', 'value' : '','disabled':false,'width':'19%'},
     'date':{'name':'date', 'type' : 'date','tagtype' : 'input', 'value' : '','disabled':false,'width':'24%'},
     'notes':{'name':'notes', 'type' : 'text','tagtype' : 'textarea', 'value' : '','disabled':false,'width':'29%'},
     'student':{'name':'student','type' : 'number','tagtype' : 'select','url':'/api/students','value' : id,'disabled':true,'width':'0%'},
    });

    
   const [mixedinputsToUpdate, setMixednputsToUpdate] = useState(
    {
     
      'isPaid':{'name':'isPaid','type' : 'number','tagtype' : 'select','url':'/api/booleans','value' : '','disabled':false,'width':'24%'},
      'attendence':{'name':'attendence','type' : 'number','tagtype' : 'select','url':'/api/booleans','value' : '','disabled':false,'width':'24%'},
      'toPay':{'name':'toPay','type' : 'number','tagtype' : 'select','url':'/api/booleans','value' : '','disabled':false,'width':'24%'},
      'notes':{'name':'notes', 'type' : 'text','tagtype' : 'textarea', 'value' : '','disabled':false,'width':'24%'},
      
    });
    const [inputsSearch, setInputsSearch] = useState(
      {
       'dateFrom':{'name':'dateFrom', 'type' : 'date','tagtype' : 'input', 'value' : '','disabled':false,'width':'19%'},
       'dateTo':{'name':'dateTo', 'type' : 'date','tagtype' : 'input', 'value' : '','disabled':false,'width':'19%'},
       'isPaid':{'name':'isPaid','type' : 'number','tagtype' : 'select','url':'/api/booleans','value' : '','disabled':false,'width':'19%'},
       'attendence':{'name':'attendence','type' : 'number','tagtype' : 'select','url':'/api/booleans','value' : '','disabled':false,'width':'19%'},
       'toPay':{'name':'toPay','type' : 'number','tagtype' : 'select','url':'/api/booleans','value' : '','disabled':false,'width':'19%'},
      });
 

   const stateHundler = (state,id) => {
     if(state === 'edit'){
       setModalState('edit');
       console.log("!!! stateHundler edit ->"+id);
       setToUpdateId(id);
     }else if(state === 'menu'){
      setModalState('menu');
      setLessonId(id);
    }else if(state === 'none'){
       setModalState('none');
     }
   }
   const onRefreshHundler = ()=> {
     if(refresh==="1"){
       setRefresh('0');
     }else{
       setRefresh('1');
     }
     if(modalState !== 'menu'){
      reloadComponent();
     }
   }
   const onListRefreshHundler = ()=> {
     if(lisRefresh==="1"){
      setLisRefresh('0');
     }else{
      setLisRefresh('1');
     }
     if(modalState !== 'menu'){
      onRefreshHundler();
     }
    
   }
   const searchInputHundler = (value) => {
    if(value && value !== " "){
      setSearchInput("search="+value);
    }else{
      setSearchInput("");
    }
    
   }
   const onMenuRefreshHundler = ()=> {
    if(refresh==="1"){
      setRefresh('0');
    }else{
      setRefresh('1');
    }
   // reloadComponent();
    
  }
   useEffect(()=>{
    onRefreshHundler();
  //  params = params +'&isPaid='+paidId;
   },[toUpdateId,lessonId])
   const onRefreshContainerHundler = ()=> {
      
    if(refreshContainer==="1"){
      setRefreshContainer('0');
    }else{
      setRefreshContainer('1');
    }
    
  }
  useEffect(()=>{
    if (headerHeight=== 1 &&  elementRef.current) {
      setHeaderContainer( elementRef.current.offsetHeight +48);
    }else if(headerHeight=== 2 &&  elementRef2.current){
      setHeaderContainer( elementRef2.current.offsetHeight +48);
    }else{
      setHeaderContainer('fit-content');
    }
  
  },[headerHeight,refreshContainer,lisRefresh]);

  useEffect(()=>{
      
    if(searchParameters){
      setUrlFinal( url + params+'&'+ searchParameters);
      setUrlInfopanel(url+"/infopanel"+params+'&'+ searchParameters);
    }else{
      setUrlFinal( url + params);
      setUrlInfopanel(url+"/infopanel"+params);
    }
  },[searchParameters]);

  useEffect(()=>{
      
    if(searchParameters || searchInput){
      setUrlFinal( url + params+'&'+ searchParameters + searchInput);
      setUrlInfopanel(url+"/infopanel"+params+'&'+ searchParameters + searchInput);
    }else{
      setUrlFinal( url + params);
      setUrlInfopanel(url+"/infopanel"+params);
    }
  },[searchParameters,searchInput]);

return (

  <div style={{width:'95%',marginInline:'auto'}}>


<FunctionalHeader height={headerContainer}  name="Participations" >
      <Infopanel url={urlInfopanel} refresh={lisRefresh}  ></Infopanel>
      <input type="text" className='header-search-input' onInput={(e) => searchInputHundler(e.target.value)} placeholder={language[language.lang].placeholderSearch} />
      <Function num={1} icon={<IconFilter/>} heightHundler={setHeaderHeight}  headerHeight={headerHeight} >
          <div key={1} ref={elementRef} style={{display:'flex',flexDirection:'row'}} >
            <SearchForm  setSearchParameters={setSearchParameters}  inputs={inputsSearch}  
            onRefreshHundler={onRefreshHundler} name={language[language.lang].modals[label].createTitle}></SearchForm>
            
          </div>
        </Function>
        <Function num={2} icon={<IconAdd/>} heightHundler={setHeaderHeight}  headerHeight={headerHeight} >
          <div key={2}  ref={elementRef2} >
            <Form   inputs={mixedinputs}  url={url} onRefreshHundler={onListRefreshHundler} 
            name={language[language.lang].modals[label].createTitle}></Form>
          </div>
        </Function>
        
      </FunctionalHeader>

   {/* <InnerHeader paidId={paidId} setPaidId={setPaidId} name="Lessons"  opened='300px' >
     <Form inputs={mixedinputs}  url={url} 
     onRefreshHundler={onRefreshHundler} 
     name={language[language.lang].modals[label].createTitle}></Form>
   </InnerHeader> */}
   <main className='main-block' >

     <div className='main-content txt-c' >
   
     <FlexList name=''
        table='table' 
        thead='table-header-bot-ans' htr='table-h-tr-bot-ans' 
        th='table-h-th-bot-lessons' tbody='table-body' 
        btr='table-b-tr-bot-lessons' td='table-b-td-bot-ans' 
        url={urlFinal} done={lisRefresh} stateHundler={stateHundler} onRefreshHundler={onListRefreshHundler} headers={headers}/>
     </div>  
     <ModalUpdate key={key} modalState={modalState} inputs={mixedinputsToUpdate}  
      url={url} toUpdate={toUpdateId }  
      onRefreshHundler={onListRefreshHundler} stateHundler={stateHundler} 
      answerId={toUpdateId} convParams={params}
      name={language[language.lang].modals[label].updateDescription} 
      title={language[language.lang].modals[label].updateTitle} />


      
    <ModalLesson modalState={modalState} lessonid={lessonId} 
       refresh={refresh}
      url={urlLessons} toUpdate={urlLessons+'/'+ lessonId }  
      onRefreshHundler={onMenuRefreshHundler} 
      stateHundler={stateHundler} 
      name={language[language.lang].modals[label].updateDescription} 
      title={language[language.lang].modals[label].updateTitle} />

   </main>
   </div>
)
}

export default Events