import axios from 'axios';
import Layout from '../shared/Layout';
import { useContext, useEffect, useState } from 'react';
import DetailsContext from '../../providers/DetailsContext';
import language from '../../variables/language';
import { useLocation, Link  } from 'react-router-dom';
import Header from '../shared/Header';
import Form from '../shared/Form';
import ActivityList from '../../components/settings/ActivityList';
import ModalUpdate from '../shared/ModalUpdate';
const Students = () => {
  // const [params, setParams] = useState({});
  // const API_URL = process.env.REACT_APP_API_URL;
  // const moduleName = 'firmy';
  const label = 'students';
  const url = '/api/students';
 // const { setTheme } = useContext(DetailsContext);
 const location = useLocation();
 const [modalState ,setModalState] = useState('none');
 const [refresh, setRefresh] = useState(0);
 const [toUpdateId , setToUpdateId] = useState('');

 const [headers, setHeaders] = useState(
   {'name':{},
    'surname':{},
    'email':{},
    'responsibleId':{'name':'firstName','name1':'lastName'},
    'hashCode':{},
    'icons':{
      'icon-delete':{'name':'icon-delete','type' : 'icon','url':url,'value' : '','mode':'delete'},
      'icon-edit':{'name':'icon-edit','type' : 'icon','url':url,'value' : '','mode':'edit'},
      'icon-copy':{'name':'icon-copy','type' : 'icon','url':'/students','value' : '','mode':'copy'},
      'icon-card':{'name':'icon-card','type' : 'icon','url':'/students','value' : '','mode':'card'},}
    }
   );
 const [inputs, setInputs] = useState(
   {
    'name':{'name':'name','type' : 'text','value' : ''},
    'surname':{'name':'surname','type' : 'text','value' : ''},
    'gMeetId':{'name':'gMeetId','type' : 'text','value' : ''},
    'email':{'name':'email','type' : 'text','value' : ''},
    'telegramUsername':{'name':'telegramUsername','type' : 'text','value' : ''},
    'facebook':{'name':'facebook','type' : 'text','value' : ''},
    
   });
 const [selectors, setSelectors] = useState({
    'responsibleId':{'name':'responsibleId','type' : 'number','url':'/api/users','value' : ''},
    'project':{'name':'project','type' : 'number','url':'/api/projects','value' : ''},
   });
 const [inputsToUpdate, setInputsToUpdate] = useState({
      'name':{'name':'name','type' : 'text','value' : ''},
      'surname':{'name':'surname','type' : 'text','value' : ''},
      'gMeetId':{'name':'gMeetId','type' : 'text','value' : ''},
      'email':{'name':'email','type' : 'text','value' : ''},
      'telegramUsername':{'name':'telegramUsername','type' : 'text','value' : ''},
      'hashCode':{'name':'hashCode','type' : 'text','value' : ''},
     
   });
 const [selectorsToUpdate, setSelectorsToUpdate] = useState({
    'responsibleId':{'name':'responsibleId','type' : 'number','url':'/api/users','value' : ''},
    'project':{'name':'project','type' : 'number','url':'/api/projects','value' : ''},
   });


   const stateHundler = (state,id) => {
     if(state === 'edit'){
       setModalState('edit');
       console.log("ID:"+id);
       setToUpdateId(id);
     }else if(state === 'none'){
       setModalState('none');
     }
   }
   const onRefreshHundler = ()=> {
     if(refresh==="1"){
       setRefresh('0');
     }else{
       setRefresh('1');
     }
     
   }


return (
 <Layout>
   <Header name={language[language.lang][(location.pathname.replace("/",""))]}   opened='300px' >
     <Form inputs={inputs} selectors={selectors} url='/api/students' onRefreshHundler={onRefreshHundler} name={language[language.lang].modals[label].createTitle}></Form>
   </Header>
   <main className='main-block' >
     <div className='main-content txt-c' >
     <ActivityList name='' url='/api/students' done={refresh} stateHundler={stateHundler} onRefreshHundler={onRefreshHundler} headers={headers}/>
     </div>  
     <ModalUpdate modalState={modalState} inputs={inputsToUpdate}  selectors={selectorsToUpdate}  url='/api/students' toUpdate={'/api/students/'+ toUpdateId }  onRefreshHundler={onRefreshHundler} stateHundler={stateHundler} name={language[language.lang].modals[label].updateDescription} title={language[language.lang].modals[label].updateTitle} />
   </main>

 </Layout>
)
}

export default Students