import { useEffect, useState, useRef, useContext } from 'react'
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthContext from '../../providers/AuthContext';
import GradientShape from '../../components/GradientShape';
import language from '../../variables/language';

const LoginForm = () => {
    const label = 'login';
    const { setIsLoggedIn, setFirstName, setLastName, setUserId ,timeZone , setTimeZone, isLoggedIn} = useContext(AuthContext);
    const API_URL = process.env.REACT_APP_API_URL;
    const errRef = useRef();
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        if(isLoggedIn===true && location === 'login'){
            navigate("/dashboard");
          }
    }, [isLoggedIn]);

    
    useEffect(() => {
        setLogin(login);
    }, [login]);
    
    useEffect(() => {
        setPassword(password);
    }, [password]);
    
    
    const handleSubmit = async(e) => {
        e.preventDefault();
        try {
            console.log("timezone"+timeZone);
            const response = await axios.post('/api/authentication',
                JSON.stringify({login, password,timeZone}), {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            setFirstName(response.data.imie);
            setLastName(response.data.nazwisko);
            setUserId(response.data.id);
            setIsLoggedIn(true);
            navigate('/');
        } catch(err) {
            if(err.response === undefined) {
                setErrMsg('Brak połączenia z serwerem');
            } 
            else if(err.response.status === 401) {
                setErrMsg('Błędny login lub hasło');
            }
            else {
                setErrMsg('Wystąpił błąd')
            }
        }
    }
    const styles_logo = {
        parenel: {  stroke: 'black', strokeWidth: 1, marginInline: 'auto',  filter:"blur(0px)",marginTop:`25vh` ,zIndex:5,},
        gradient: {  },
        colorOne: { stopColor:`rgba(101, 100, 100, 0.32)`},
        colorTwo: { stopColor:`#0bc6d9` },
    };
    

  return (
    <div className="login-form-container">
        <div className='logo-container' >
             <GradientShape styles={styles_logo} />
        </div>
        
        <div className='login-form-block'>
            <div className="login-form-header">
                <h2 className="login-form-header-txt">{language[language.lang].pages[label].loginTitle}</h2>
            </div>

            <div className="login-form-body">
                <form className="" action="#" method="POST" onSubmit={handleSubmit}>
                        <div className="inpt-login">
                            <input placeholder={language[language.lang].pages[label].loginPlaceholder} style={{paddingLeft: '12px'}} onChange={(e) => setLogin(e.target.value)} id="text" name="text" type="text" autoComplete="text" required className="login-input" />
                        </div>
                        <div className="inpt-login">
                            <input placeholder={language[language.lang].pages[label].passPlaceholder} style={{paddingLeft: '12px'}} onChange={(e) => setPassword(e.target.value)} id="password" name="password" type="password" autoComplete="current-password" required className={`${errMsg ? "errmsg " : ""} login-input`} />
                        </div>
                    <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                    <div>
                        <button type="submit" className="btn-login">{language[language.lang].pages[label].loginButton}</button>
                    </div>
                </form>
            </div>
        </div>
        
    </div>
  )
}

export default LoginForm