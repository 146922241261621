import React, { useEffect, useState } from 'react'
import LessonCard from './LessonCard'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Miesiące są indeksowane od 0
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export default function LessonsColumn(props) {
 
  const today = formatDate(new Date());
   const [color, setColor] = useState( props.day.date === today ? 'green' : 'gray');
   const [title, setTitle] = useState(props.title);
   const [id, setId] = useState(props.day.date);
   const [url, setUrl] = useState(props.url+"?date="+id);
   const navigate = useNavigate();
   const [data,setData] =  useState([]);
   const [satatus, setSatatus] = useState('wait');
   const [refresh, setRefresh] = useState(0);

   const onRefreshHundler = ()=> {
   
    if(refresh==="1"){
      setRefresh('0');
    }else{
      setRefresh('1');
    }
    
  }

  const [fetcher, setFetcher] = useState(0);

   const onFetcherHundler = ()=> {
   
    if(fetcher==="1"){
      setFetcher('0');
    }else{
      setFetcher('1');
    }
    
  }

   useEffect(() => {
      if(satatus === 'none'){

      }else if(satatus === 'wait'){
       
      }else if(satatus === 'done'){
     
      }else if(satatus === 'error'){
        
      }else if(satatus === 'forbidden'){
      
      }else if(satatus === 'timeout'){
        
      }
    }, [satatus]);

    useEffect(()=>{
      setData([]);
      setId(props.day.date);
      setUrl(props.url+"?date="+props.day.date);
    //  console.log(props.day.date+ " in column refresh");
      onFetcherHundler();
    },[props.data])

   useEffect(()=>{
     
        //console.log("request in column url :"+url);
        axios.get(url, { withCredentials: true })
        .then(response => {
          setData(response.data);
          
          onRefreshHundler();
        
        })
        .catch(err => {
          if(err.response && err.response.status === 401){
           navigate('/login');
          }
        
          console.log(err);
        });
      
    
    
   },[fetcher])

  

  return (
    <div className='pipe-column' key={props.key} style={props.style}>
      <div style={{borderBottom:`3px solid ${props.day.date === today ? 'green':'rgb(70, 126, 151)'}`, paddingBlock:3,
       background:props.day.date === today ?'rgb(95 231 205 / 15%)': 'transparent',
      position:'relative'}} className='pipe-column-header'>
        <p style={{textAlign:'left',marginLeft:3}}>{props.day.dayOfWeek}</p>
        <p style={{color:'gray',fontSize:13,textAlign:'right'}}>{props.day.date}</p>
        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
          <p style={{position:'absolute',top:-12,right:1,color:'black',borderRadius:'6px 6px 6px 0px',
            border:'1px solid gray',padding:1,background:'#5ef28bde',
             display: props.day.date === today ? 'block':'none'}} >Today</p>
          <p style={{marginInline:4}}>{props.day.earned}zł</p>
          <p style={{marginInline:4,color:'#667eff'}}>{props.day.hours}</p>
        </div>
       
        
        </div>
         {Array.isArray(data) ? (
               data.map(item => (
                  <LessonCard  key={item.id} stateHundler={props.stateHundler} 
                  onRefresh={onRefreshHundler}
                  onRootRefresh={props.onRootRefresh}
                  data={data}
                     id={item.id}
                     url={props.url}
                     color={color}
                     item ={item}
                     start = {item.start}
                     title={item.start+" - "+ item.end} 
                     price={item.price} 
                     probability={item.probability}
                     uMod={ item.teacher? item.teacher.firstName+' '+item.teacher.lastName : ''}
                     product={item.course}
                     duration = {item.duration}
                     today ={today}
                     date = {id}
                    //  product={(item.product && typeof item.product === 'object')? item.product.name :"None"}
                     ></LessonCard>
               ))
            ) : (
               <div>Empty list</div>
         )}
    </div>
  )
}
